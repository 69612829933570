
import React, { useState, useEffect } from "react";
import { Building, ShoppingBag, Percent, CircleDollarSign, TrendingUp, Shield, BadgePercent } from "lucide-react";
import { useIsMobile } from "@/hooks/use-mobile";
import { motion } from "framer-motion";

// Este componente mostra estatísticas da plataforma com um efeito de contagem
export function PlatformStats() {
  const [counts, setCounts] = useState({
    suppliers: 0,
    medicines: 0,
    savings: 0
  });
  const targetCounts = {
    suppliers: 52,
    medicines: 1847,
    savings: 40
  };
  const isMobile = useIsMobile();
  
  useEffect(() => {
    // Animação de contagem para cada estatística
    const duration = 2000; // 2 segundos para a animação completa
    const frameDuration = 1000 / 60; // Assumindo 60fps
    const totalFrames = Math.round(duration / frameDuration);
    let frame = 0;
    const counter = setInterval(() => {
      frame++;
      const progress = frame / totalFrames;
      setCounts({
        suppliers: Math.floor(progress * targetCounts.suppliers),
        medicines: Math.floor(progress * targetCounts.medicines),
        savings: Math.floor(progress * targetCounts.savings)
      });
      if (frame === totalFrames) {
        clearInterval(counter);
        setCounts(targetCounts);
      }
    }, frameDuration);
    return () => clearInterval(counter);
  }, []);

  return (
    <section className="py-16 md:py-20 px-4 bg-gradient-to-b from-blue-50/50 to-white">
      <div className="container mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-4 bg-gradient-to-r from-primary via-primary-dark to-primary bg-clip-text text-transparent">
            Nosso Impacto no Mercado
          </h2>
          <div className="w-24 h-1 bg-gradient-to-r from-primary to-primary-light mx-auto"></div>
        </motion.div>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          <StatCard 
            icon={<Shield className="w-6 h-6 text-white" />} 
            count={16000} 
            suffix="+" 
            label="Cotações Geradas"
            delay={0.1}
          />
          <StatCard 
            icon={<CircleDollarSign className="w-6 h-6 text-white" />} 
            count={900} 
            prefix="R$ " 
            suffix=" milhões" 
            label="Em orçamentos (2020-2024)"
            delay={0.2}
          />
          <StatCard 
            icon={<TrendingUp className="w-6 h-6 text-white" />} 
            count={360} 
            prefix="R$ " 
            suffix=" milhões" 
            label="Em orçamentos somente em 2024"
            delay={0.3}
          />
          <StatCard 
            icon={<BadgePercent className="w-6 h-6 text-white" />} 
            count={6.43} 
            suffix="%" 
            label="Do mercado de alto custo em 2024*"
            delay={0.4}
          />
        </div>
        
        <div className="text-center mt-8 text-xs text-gray-500">
          <p>*Referente ao mercado farmacêutico de medicamentos de alto custo no Brasil.</p>
        </div>
      </div>
    </section>
  );
}

interface StatCardProps {
  icon: React.ReactNode;
  count: number;
  label: string;
  prefix?: string;
  suffix?: string;
  delay?: number;
}

const StatCard = ({
  icon,
  count,
  label,
  prefix = "",
  suffix = "",
  delay = 0
}: StatCardProps) => {
  return (
    <motion.div 
      initial={{
        opacity: 0,
        y: 30
      }} 
      whileInView={{
        opacity: 1,
        y: 0
      }} 
      transition={{
        duration: 0.5,
        delay
      }} 
      viewport={{
        once: true
      }}
      className="bg-white rounded-xl shadow-md border border-blue-100/40 p-6 md:p-8 text-center hover:shadow-lg transition-shadow duration-300 relative overflow-hidden group"
    >
      <div className="absolute inset-0 bg-gradient-to-br from-blue-50/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
      
      <div className="mx-auto w-16 h-16 flex items-center justify-center rounded-full bg-gradient-to-r from-primary to-primary-light mb-5 relative z-10 group-hover:scale-110 transition-transform duration-300">
        {icon}
      </div>
      
      <h3 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-2 flex items-center justify-center flex-wrap">
        <span className="mr-1">{prefix}</span>
        <span className="tabular-nums">{count.toLocaleString('pt-BR')}</span>
        <span className="text-primary">{suffix}</span>
      </h3>
      
      <p className="text-gray-600 font-medium line-clamp-2 h-12 flex items-center justify-center">{label}</p>
      
      <div className="absolute bottom-0 left-0 h-1 w-full bg-gradient-to-r from-transparent via-primary/30 to-transparent"></div>
    </motion.div>
  );
};
