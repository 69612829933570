
import React, { useState, useEffect } from 'react';
import { MessageSquare } from 'lucide-react';
import { trackButtonClick } from '@/utils/trackingUtils';
import { useUtmParams } from '@/hooks/useUtmParams';

export const WhatsAppButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const utmParams = useUtmParams();
  
  useEffect(() => {
    // Check if we're on an admin page or login page
    const isAdminPage = window.location.pathname.startsWith('/admin');
    const isLoginPage = window.location.pathname === '/login';
    
    // Don't show on admin or login pages
    if (isAdminPage || isLoginPage) {
      setIsVisible(false);
      return;
    }
    
    // Show button after scrolling down a bit or immediately if on mobile
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else if (window.scrollY <= 50) {
        // Always show on all devices
        setIsVisible(true);
      }
    };
    
    // Always show button on all pages
    setIsVisible(true);
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    // Small delay before showing button on first load
    const timer = setTimeout(() => setIsVisible(true), 300);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timer);
    };
  }, []);
  
  const handleClick = () => {
    // Track this interaction with Google Analytics
    trackButtonClick(
      'Floating WhatsApp Button', 
      'whatsapp', 
      'https://bit.ly/digisacpm',
      utmParams
    );
    
    window.open('https://bit.ly/digisacpm', '_blank');
  };
  
  return (
    <div 
      className={`fixed bottom-4 sm:bottom-6 left-4 sm:left-6 z-50 transition-all duration-300 ${
        isVisible ? 'translate-y-0 opacity-100' : 'translate-y-20 opacity-0'
      }`}
      aria-hidden={!isVisible}
    >
      <button
        onClick={handleClick}
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onTouchStart={() => setShowTooltip(true)}
        onTouchEnd={() => setTimeout(() => setShowTooltip(false), 1500)}
        aria-label="Fale conosco pelo WhatsApp"
        className="bg-green-500 hover:bg-green-600 text-white p-3 sm:p-4 rounded-full shadow-lg hover:shadow-xl transform hover:scale-105 transition-all duration-300 relative touch-manipulation"
      >
        <MessageSquare className="h-6 w-6 sm:h-7 sm:w-7" />
        
        {/* Green pulse animation - optimized for performance */}
        <span className="absolute inset-0 rounded-full animate-ping bg-green-500 opacity-40 pointer-events-none"></span>
        
        {/* Tooltip - Made more touch-friendly */}
        <div 
          className={`absolute left-14 bottom-0 bg-white text-gray-800 px-3 py-1.5 rounded-lg shadow-md whitespace-nowrap text-xs sm:text-sm font-medium transition-all duration-300 max-w-[200px] ${
            showTooltip ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-5 pointer-events-none'
          }`}
        >
          Fale conosco no WhatsApp
          <span className="absolute -left-1.5 top-1/2 -translate-y-1/2 bg-white h-3 w-3 transform rotate-45"></span>
        </div>
      </button>
    </div>
  );
};
