
// Session timeout in milliseconds (30 minutes)
const SESSION_TIMEOUT = 30 * 60 * 1000;

// Track user activity
let lastActivityTime = Date.now();

export const updateLastActivity = () => {
  lastActivityTime = Date.now();
};

// Check if the session is expired due to inactivity
export const isSessionExpired = () => {
  return Date.now() - lastActivityTime > SESSION_TIMEOUT;
};

// Helper function to check if we have a valid session
export const hasValidSession = async () => {
  try {
    const { data } = await import('@/integrations/supabase/client').then(mod => mod.supabase.auth.getSession());
    
    // Check if session exists and is valid
    if (!data?.session) return false;
    
    // Parse the token to check if it's expired
    try {
      const token = data.session?.access_token;
      if (!token) return false;
      
      // Decode the JWT to check expiration
      const base64Url = token.split('.')[1];
      if (!base64Url) return false;
      
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      
      const payload = JSON.parse(jsonPayload);
      const expirationTime = payload.exp * 1000; // Convert to ms
      
      // If token is expired or about to expire in the next minute, consider it invalid
      if (expirationTime < Date.now() + 60000) {
        console.log("Session token expired or expiring soon");
        return false;
      }
    } catch (parseError) {
      console.warn("Error parsing token:", parseError);
      // If we can't parse the token, consider the session valid if it exists
      return !!data.session;
    }
    
    return true;
  } catch (error) {
    console.error("Erro ao verificar sessão:", error);
    return false;
  }
};

// Safe storage operations with fallbacks for Safari private browsing mode
export const safeSetItem = (storage: Storage, key: string, value: string) => {
  try {
    storage.setItem(key, value);
  } catch (e) {
    console.warn(`Não foi possível salvar ${key} no storage`, e);
    // Try sessionStorage as a fallback if localStorage fails
    if (storage === localStorage) {
      try {
        sessionStorage.setItem(key, value);
      } catch (e2) {
        console.error(`Não foi possível salvar ${key} mesmo no sessionStorage`, e2);
      }
    }
  }
};

export const safeGetItem = (key: string): string | null => {
  try {
    // Try localStorage first
    const value = localStorage.getItem(key);
    if (value !== null) return value;
    
    // Fall back to sessionStorage
    return sessionStorage.getItem(key);
  } catch (e) {
    console.warn(`Erro ao acessar storage para ${key}`, e);
    try {
      // Try sessionStorage if localStorage fails
      return sessionStorage.getItem(key);
    } catch (e2) {
      console.error(`Não foi possível acessar ${key} mesmo no sessionStorage`, e2);
      return null;
    }
  }
};

export const safeRemoveItem = (key: string) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    console.warn(`Não foi possível remover ${key} do localStorage`, e);
  }
  
  try {
    sessionStorage.removeItem(key);
  } catch (e) {
    console.warn(`Não foi possível remover ${key} do sessionStorage`, e);
  }
};

// Function to clear all auth-related data from localStorage and sessionStorage
export const clearAuthData = () => {
  if (typeof window !== 'undefined') {
    console.log("Limpando todos os dados de autenticação");

    // Clear Supabase-specific entries
    safeRemoveItem('supabase.auth.token');
    safeRemoveItem('sb-refresh-token');
    safeRemoveItem('sb-access-token');
    safeRemoveItem('supabase.auth.refreshToken');
    safeRemoveItem('userRole');
    safeRemoveItem('isAuthenticated');
    safeRemoveItem('partnerId');
    safeRemoveItem('partnerName');
    
    // Attempt to clear all Supabase-related items from both storages
    try {
      // Remove all localStorage items that might be related to auth
      const storagesToClear = [localStorage, sessionStorage];
      
      storagesToClear.forEach(storage => {
        try {
          const keys = Object.keys(storage);
          keys.forEach(key => {
            if (key.startsWith('sb-') || 
                key.includes('supabase') || 
                key === 'userRole' || 
                key === 'isAuthenticated') {
              try {
                storage.removeItem(key);
              } catch (e) {
                console.warn(`Erro ao remover item ${key} do storage:`, e);
              }
            }
          });
        } catch (e) {
          console.warn("Erro ao acessar storage keys:", e);
        }
      });
    } catch (e) {
      console.warn("Erro ao limpar itens de storage:", e);
    }
    
    // Update last activity
    updateLastActivity();
  }
};

// Function to handle token recovery
export const recoverSession = async () => {
  try {
    // Check if we have a refresh token
    const refreshToken = safeGetItem('sb-refresh-token');
    
    if (!refreshToken) {
      console.log("Nenhum token de atualização encontrado para recuperar a sessão");
      return false;
    }
    
    console.log("Tentando recuperar sessão com token de atualização");
    
    // Import the Supabase client directly to avoid circular dependencies
    const { supabase } = await import('@/integrations/supabase/client');
    
    // Attempt to refresh the session
    const { data, error } = await supabase.auth.refreshSession();
    
    if (error) {
      console.error("Erro ao atualizar sessão:", error);
      clearAuthData(); // Clear invalid tokens
      return false;
    }
    
    if (data && data.session) {
      console.log("Sessão recuperada com sucesso");
      return true;
    }
    
    return false;
  } catch (error) {
    console.error("Erro ao recuperar sessão:", error);
    return false;
  }
};

// Function to safely access the last console error
export const getLastConsoleError = (): Error | undefined => {
  if (typeof window === 'undefined') return undefined;
  return (window as any).__lastConsoleError;
};

// Set up activity listeners with debounce to prevent excessive updates
export const initActivityListeners = () => {
  if (typeof window !== 'undefined') {
    let debounceTimer: number | null = null;
    
    // Debounced function to update last activity time
    const debouncedUpdateActivity = () => {
      if (debounceTimer !== null) {
        window.clearTimeout(debounceTimer);
      }
      
      debounceTimer = window.setTimeout(() => {
        updateLastActivity();
        debounceTimer = null;
      }, 300); // 300ms debounce
    };
    
    const events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, debouncedUpdateActivity, { passive: true });
    });

    // Regular check to detect expired sessions
    const intervalId = setInterval(async () => {
      if (isSessionExpired()) {
        // Force session check when activity timeout is reached
        const hasSession = await hasValidSession();
        if (!hasSession) {
          // Try to recover session first
          const recovered = await recoverSession();
          
          if (!recovered && window.location.pathname.includes('/admin/')) {
            console.log("Sessão expirada e não foi possível recuperar, redirecionando para login");
            clearAuthData(); // Clear all auth data before redirect
            window.location.href = '/login';
          }
        }
      }
    }, 60000); // Check every minute

    return () => {
      clearInterval(intervalId);
      events.forEach(event => {
        window.removeEventListener(event, debouncedUpdateActivity);
      });
      if (debounceTimer !== null) {
        window.clearTimeout(debounceTimer);
      }
    };
  }
};
