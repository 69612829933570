
import React, { useId } from 'react';
import { Loader2 } from 'lucide-react';
import { cn } from "@/lib/utils";

export interface LoadingProps {
  size?: 'xs' | 'sm' | 'md' | 'lg';
  text?: string | null;
  fullScreen?: boolean;
  className?: string;
  showBackground?: boolean;
  inline?: boolean;
  timeout?: number; // Add timeout option
  suspenseFallback?: boolean; // Specific flag for Suspense boundaries
}

export function Loading({
  size = 'md',
  text = 'Carregando...',
  fullScreen = false,
  className = '',
  showBackground = true,
  inline = false,
  timeout = 0, // Default no timeout
  suspenseFallback = false, // Default not used as Suspense fallback
}: LoadingProps) {
  const id = useId();
  const [showError, setShowError] = React.useState(false);
  
  const sizeClasses = {
    xs: 'h-3 w-3',
    sm: 'h-4 w-4',
    md: 'h-8 w-8',
    lg: 'h-12 w-12',
  };

  // Set up timeout to show "taking longer than expected" message
  React.useEffect(() => {
    if (timeout > 0) {
      const timer = setTimeout(() => {
        setShowError(true);
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [timeout]);

  if (fullScreen) {
    return (
      <div 
        className={cn(
          "fixed inset-0 flex flex-col items-center justify-center z-[9999]",
          showBackground ? "bg-background/80 backdrop-blur-sm" : "",
          className
        )}
        aria-labelledby={`loading-text-${id}`}
        role="alert"
        aria-live="polite"
      >
        <Loader2 className={`${sizeClasses[size]} animate-spin text-primary`} />
        {text && <p id={`loading-text-${id}`} className="mt-2 text-sm text-muted-foreground max-w-xs text-center px-4">{text}</p>}
        
        {showError && (
          <div className="mt-4 text-center max-w-xs px-4">
            <p className="text-sm text-amber-600 dark:text-amber-400">
              Está demorando mais do que o esperado. 
              <button 
                onClick={() => window.location.reload()}
                className="ml-1 underline text-primary"
              >
                Tentar recarregar
              </button>
            </p>
          </div>
        )}
      </div>
    );
  }

  if (suspenseFallback) {
    // Special style for Suspense fallbacks, centered with padding
    return (
      <div className={cn("flex flex-col items-center justify-center p-6 min-h-[200px]", className)}>
        <Loader2 className={`${sizeClasses[size]} animate-spin text-primary`} />
        {text && <p className="mt-3 text-sm text-muted-foreground">{text}</p>}
      </div>
    );
  }

  if (inline) {
    return (
      <span className={cn("inline-flex items-center gap-1", className)}>
        <Loader2 className={`${sizeClasses[size]} animate-spin text-primary`} />
        {text && <span className="text-sm text-muted-foreground">{text}</span>}
      </span>
    );
  }

  return (
    <div className={cn("flex flex-col items-center justify-center p-4", className)}>
      <Loader2 className={`${sizeClasses[size]} animate-spin text-primary`} />
      {text && <p className="mt-2 text-sm text-muted-foreground">{text}</p>}
      
      {showError && (
        <p className="mt-2 text-sm text-amber-600 dark:text-amber-400">
          Está demorando mais do que o esperado
        </p>
      )}
    </div>
  );
}

export const Skeleton = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={cn("animate-pulse rounded-md bg-muted", className)}
      {...props}
    />
  );
};
