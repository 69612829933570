
// Error tracking utility for system monitoring
import { shouldSuppressLogs } from './consoleSuppressionUtils';

let errorTrackingInitialized = false;

/**
 * Safely declare the window.__lastConsoleError property for TypeScript
 */
declare global {
  interface Window {
    __lastConsoleError?: Error;
    __moduleLoadRetry?: Record<string, number>;
    __edgeFunctionFailures?: Record<string, {
      count: number,
      lastError: string,
      lastAttempt: number
    }>;
  }
}

// Initialize error tracking for the application
export function initErrorTracking() {
  if (errorTrackingInitialized) return;
  
  // Only log in development or with debug mode
  if (!shouldSuppressLogs()) {
    console.info('Sistema de monitoramento inicializado');
  }
  
  // Initialize the properties if they don't exist
  if (typeof window !== 'undefined') {
    window.__lastConsoleError = undefined;
    window.__moduleLoadRetry = {};
    window.__edgeFunctionFailures = {};
  }
  
  // Original console methods
  const originalConsoleError = console.error;
  const originalConsoleWarn = console.warn;
  const originalConsoleLog = console.log;
  
  // Function to sanitize sensitive data from error messages
  const sanitizeErrorMessage = (args: any[]) => {
    // Don't process in development mode
    if (!shouldSuppressLogs()) {
      return args;
    }
    
    const sanitizedArgs = args.map(arg => {
      // Skip non-string arguments
      if (typeof arg !== 'string') return arg;
      
      // Mask email addresses
      const emailMasked = arg.replace(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi, 
        '***@***.***'
      );
      
      // Mask tokens and remove any references to platform names
      return emailMasked
        .replace(/(eyJ[a-zA-Z0-9_-]{5,}\.eyJ[a-zA-Z0-9_-]{5,})/g, '***TOKEN***')
        .replace(/(lovable|supabase)/gi, 'sistema');
    });
    
    return sanitizedArgs;
  };
  
  // Replace console.error
  console.error = function (...args) {
    // Check for module loading errors
    try {
      const errorMsg = args.join(' ');
      
      // Track edge function errors
      if (errorMsg.includes('Edge Function returned a non-2xx status code') || 
          errorMsg.includes('Error invoking') || 
          errorMsg.includes('FunctionsHttpError')) {
        
        // Extract function name
        let functionName = 'unknown-function';
        const functionMatch = errorMsg.match(/invoking ([a-z-]+) function/i);
        if (functionMatch && functionMatch[1]) {
          functionName = functionMatch[1];
        }
        
        if (typeof window !== 'undefined') {
          // Initialize edge function failure tracking
          window.__edgeFunctionFailures = window.__edgeFunctionFailures || {};
          
          // Track this failure
          if (!window.__edgeFunctionFailures[functionName]) {
            window.__edgeFunctionFailures[functionName] = {
              count: 0,
              lastError: errorMsg,
              lastAttempt: Date.now()
            };
          }
          
          window.__edgeFunctionFailures[functionName].count++;
          window.__edgeFunctionFailures[functionName].lastError = errorMsg;
          window.__edgeFunctionFailures[functionName].lastAttempt = Date.now();
          
          if (!shouldSuppressLogs()) {
            console.warn(`Falha na função: "${functionName}". Contagem: ${window.__edgeFunctionFailures[functionName].count}`);
          }
        }
      }
      
      // Module loading error handling - improved for mobile compatibility
      if (errorMsg.includes('Failed to fetch dynamically imported module')) {
        const moduleMatch = errorMsg.match(/: ([^:]+\.js)/);
        const moduleName = moduleMatch ? moduleMatch[1] : 'unknown-module';
        
        // Track retry attempts for this module
        if (typeof window !== 'undefined') {
          window.__moduleLoadRetry = window.__moduleLoadRetry || {};
          window.__moduleLoadRetry[moduleName] = (window.__moduleLoadRetry[moduleName] || 0) + 1;
          
          if (!shouldSuppressLogs()) {
            console.warn(`Erro ao carregar módulo ${moduleName}. Tentativa: ${window.__moduleLoadRetry[moduleName]}`);
          }
          
          // If we haven't tried reloading 3 times, reload the page
          // But only on desktop - avoid infinite reloads on mobile
          const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
          if (window.__moduleLoadRetry[moduleName] < 3 && !isMobile) {
            if (!shouldSuppressLogs()) {
              console.warn(`Tentando recarregar a página para corrigir problema de carregamento: ${moduleName}`);
            }
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            return;
          }
        }
      }
    } catch (e) {
      // Fall through if error detection fails
    }
    
    // Capture the error for system monitoring
    try {
      // Create an error object to capture the stack trace
      const errorMessage = args.join(' ');
      if (typeof window !== 'undefined') {
        window.__lastConsoleError = new Error(errorMessage);
        
        // Store in localStorage for persistent tracking (if available)
        try {
          const storedErrors = JSON.parse(localStorage.getItem('system_errors') || '[]');
          if (Array.isArray(storedErrors) && storedErrors.length < 10) {
            storedErrors.push({
              timestamp: new Date().toISOString(),
              component: 'Frontend',
              message: errorMessage,
              stackTrace: window.__lastConsoleError.stack,
              resolved: false
            });
            localStorage.setItem('system_errors', JSON.stringify(storedErrors));
          }
        } catch (e) {
          // Silent failure for localStorage issues
        }
      }
    } catch (e) {
      // Fail silently
    }
    
    // Skip logging in production if suppression is active
    if (shouldSuppressLogs()) {
      return;
    }
    
    // Log to the original console.error in development or debug mode
    originalConsoleError.apply(console, sanitizeErrorMessage(args));
    
    try {
      // Send severe errors to server-side logging in production
      if (process.env.NODE_ENV === 'production') {
        // Non-blocking error reporting
        setTimeout(() => {
          // Implementation left empty to avoid potential tracking
          // This would normally send errors to a monitoring service
        }, 0);
      }
    } catch (e) {
      // Fail silently
    }
  };
  
  // Replace console.warn
  console.warn = function (...args) {
    // Skip logging in production if suppression is active
    if (shouldSuppressLogs()) {
      return;
    }
    
    // Log to the original console.warn
    originalConsoleWarn.apply(console, sanitizeErrorMessage(args));
  };
  
  // Replace console.log
  console.log = function (...args) {
    // Skip logging in production if suppression is active
    if (shouldSuppressLogs()) {
      return;
    }
    
    // Log to the original console.log
    originalConsoleLog.apply(console, sanitizeErrorMessage(args));
  };
  
  // Add global error handler with module loading retry capability 
  // Improved with mobile detection
  window.addEventListener('error', (event) => {
    // Don't trigger reloads on mobile devices
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    // Check if it's a module loading error
    if (event.error?.message?.includes('Failed to fetch dynamically imported module') && !isMobile) {
      console.error('Erro de carregamento de módulo não tratado:', event.error);
      
      // Will be handled by our console.error override above
      return false;
    }
    
    console.error('Erro não tratado:', event.error);
    return false; // Allow default error handling
  });
  
  // Add unhandled promise rejection handler
  window.addEventListener('unhandledrejection', (event) => {
    console.error('Rejeição de promessa não tratada:', event.reason);
    return false; // Allow default rejection handling
  });
  
  errorTrackingInitialized = true;
}

// Function to log a handled error
export function logError(error: Error | string, context?: any) {
  if (!errorTrackingInitialized) {
    initErrorTracking();
  }
  
  console.error(`Handled error: ${error}`, context ? { context } : '');
}

// Export a function to disable console overrides during development
export function resetConsoleOverrides() {
  if (process.env.NODE_ENV !== 'production') {
    console.info('Console overrides reset for development');
    // This would restore original console methods in development
  }
}

// Get the last console error (with type checking)
export function getLastConsoleError(): Error | undefined {
  return typeof window !== 'undefined' ? window.__lastConsoleError : undefined;
}

// Clear the last console error
export function clearLastConsoleError(): void {
  if (typeof window !== 'undefined') {
    window.__lastConsoleError = undefined;
  }
}

// Check if the user is on a mobile device
export function isMobileDevice(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}
