
import { useState, useEffect } from "react";
import { ArrowRight } from "lucide-react";
import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { GlassCard } from "@/components/GlassCard";
import { Loading } from "@/components/ui/loading";
import { getOptimizedImageProps } from "@/utils/imageOptimizer";
import { supabase } from "@/integrations/supabase/client";
import { dbToFrontendBlogPost, BlogPost } from "@/utils/blogUtils";

export function RecentBlogPosts() {
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchRecentBlogPosts();
  }, []);

  const fetchRecentBlogPosts = async () => {
    const cachedPosts = localStorage.getItem('recent-blog-posts');
    const cachedTime = localStorage.getItem('recent-blog-posts-time');
    
    if (cachedPosts && cachedTime && (Date.now() - parseInt(cachedTime)) < 600000) {
      setBlogPosts(JSON.parse(cachedPosts));
      setLoading(false);
      
      setTimeout(fetchPostsFromAPI, 5000);
      return;
    }
    
    await fetchPostsFromAPI();
  };
  
  const fetchPostsFromAPI = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('blog_posts')
        .select('id, title, slug, excerpt, featured_image, alt_text, meta_description, category, status, published_at')
        .eq('status', 'published')
        .order('published_at', { ascending: false })
        .limit(3);
        
      if (error) throw error;
      
      if (data && data.length > 0) {
        const formattedPosts = data.map(post => dbToFrontendBlogPost({
          id: post.id,
          title: post.title,
          slug: post.slug,
          excerpt: post.excerpt || '',
          content: '',
          category: post.category,
          featured_image: post.featured_image,
          alt_text: post.alt_text,
          meta_description: post.meta_description,
          tags: [],
          seo_score: 0,
          status: post.status,
          created_at: '',
          updated_at: '',
          published_at: post.published_at,
          author_id: '',
          views: 0,
          author_name: '',
          tone: '',
          image_style: '',
          short_tail_keywords: [],
          long_tail_keywords: []
        }));
        
        setBlogPosts(formattedPosts);
        
        localStorage.setItem('recent-blog-posts', JSON.stringify(formattedPosts));
        localStorage.setItem('recent-blog-posts-time', Date.now().toString());
      }
    } catch (error) {
      console.error("Erro ao buscar posts do blog:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="py-24 bg-gray-50 dark:bg-gray-800/50 relative">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_70%_60%,rgba(0,141,213,0.1),transparent)]" />
      
      <div className="container relative z-10">
        <div className="text-center mb-16">
          <span className="text-primary font-semibold uppercase tracking-wider px-4 py-1.5 bg-primary/10 rounded-full inline-block mb-4">Conhecimento</span>
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-6">Artigos Recentes</h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Informações importantes sobre tratamentos, medicamentos e dicas de saúde.
          </p>
        </div>
        
        {loading ? (
          <div className="flex justify-center items-center py-6">
            <Loading size="sm" text="Carregando artigos..." />
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {blogPosts.length > 0 ? (
              blogPosts.map(post => (
                <Link key={post.id} to={`/blog/${post.slug}`} className="group">
                  <GlassCard className="overflow-hidden">
                    <div className="h-52 overflow-hidden">
                      <img 
                        {...getOptimizedImageProps(
                          post.featuredImage || "/placeholder.svg", 
                          post.altText || post.title, 
                          "w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-700", 
                          "(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                        )} 
                      />
                    </div>
                    <div className="p-6">
                      <span className="text-xs font-semibold text-primary bg-primary/10 px-3 py-1 rounded-full">
                        {post.category || "Saúde"}
                      </span>
                      <h3 className="text-xl font-semibold mt-3 mb-2 line-clamp-2 group-hover:text-primary transition-colors">{post.title}</h3>
                      <p className="text-gray-600 dark:text-gray-300 mb-4 line-clamp-3">
                        {post.excerpt || post.metaDescription || ""}
                      </p>
                      <div className="flex items-center text-primary font-medium group-hover:translate-x-1 transition-transform">
                        Ler mais
                        <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
                      </div>
                    </div>
                  </GlassCard>
                </Link>
              ))
            ) : (
              Array.from({ length: 3 }).map((_, index) => (
                <GlassCard key={index} className="overflow-hidden">
                  <div className="h-52 skeleton"></div>
                  <div className="p-6">
                    <div className="w-24 h-6 skeleton mb-3 rounded-full"></div>
                    <div className="w-full h-7 skeleton mb-2"></div>
                    <div className="w-full h-6 skeleton mb-1"></div>
                    <div className="w-3/4 h-6 skeleton mb-4"></div>
                    <div className="w-24 h-6 skeleton"></div>
                  </div>
                </GlassCard>
              ))
            )}
          </div>
        )}
        
        <div className="text-center mt-16">
          <Button asChild variant="outline" size="lg" className="border-primary text-primary hover:bg-primary/10 dark:border-primary/60 dark:text-primary/90">
            <Link to="/blog">
              Ver Todos os Artigos
              <ArrowRight className="ml-2 h-5 w-5" />
            </Link>
          </Button>
        </div>
      </div>
    </section>
  );
}
