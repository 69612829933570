
import { Alert, AlertTitle, AlertDescription } from "@/components/ui/alert";
import { CheckCircle, AlertCircle } from "lucide-react";

interface FormAlertsProps {
  showSuccessAlert: boolean;
  submitError: string | null;
}

export const FormAlerts = ({ showSuccessAlert, submitError }: FormAlertsProps) => {
  return (
    <>
      {showSuccessAlert && (
        <Alert variant="success" className="mb-4 animate-in fade-in duration-300">
          <CheckCircle className="h-5 w-5" />
          <AlertTitle>Solicitação enviada com sucesso!</AlertTitle>
          <AlertDescription>
            Recebemos sua solicitação de cotação e entraremos em contato em breve.
          </AlertDescription>
        </Alert>
      )}
      
      {submitError && (
        <Alert variant="destructive" className="mb-4 animate-in fade-in duration-300">
          <AlertCircle className="h-5 w-5" />
          <AlertTitle>Erro ao enviar solicitação</AlertTitle>
          <AlertDescription>{submitError}</AlertDescription>
        </Alert>
      )}
    </>
  );
};
