
import { Pill, Award, ThumbsUp, BadgeCheck } from "lucide-react";
import { ModernFeatureCard } from "@/components/ModernFeatureCard";

export function FeatureSection() {
  return (
    <section className="py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-b from-white/0 via-primary/5 to-white/0 dark:from-gray-900/0 dark:via-primary/10 dark:to-gray-900/0" />
      
      <div className="container relative z-10">
        <div className="text-center mb-16">
          <span className="text-primary font-semibold uppercase tracking-wider px-4 py-1.5 bg-primary/10 rounded-full inline-block mb-4">Nossos Diferenciais</span>
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-6">Medicamentos Especiais <br /> 
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary via-accent to-primary-dark">Para Todos</span>
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Acesso facilitado a tratamentos especializados e de alto custo
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
          <ModernFeatureCard 
            icon={Pill} 
            title="Medicamentos Especiais" 
            description="Acesso aos medicamentos mais raros e de alto custo do mercado farmacêutico." 
          />
          
          <ModernFeatureCard 
            icon={Award} 
            title="Melhores Preços" 
            description="Comparamos preços e condições para garantir a melhor oferta disponível." 
          />
          
          <ModernFeatureCard 
            icon={ThumbsUp} 
            title="Atendimento Personalizado" 
            description="Suporte especializado para encontrar exatamente o que você precisa." 
          />
          
          <ModernFeatureCard 
            icon={BadgeCheck} 
            title="Produtos Verificados" 
            description="Qualidade e autenticidade garantidas em todos os medicamentos." 
          />
        </div>
      </div>
    </section>
  );
}
