
import { useState, useEffect } from 'react';

// Update the interface to extend Record<string, string | undefined>
// This allows it to be used where Record<string, string> is expected
export interface UtmParams extends Record<string, string | undefined> {
  source?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  gclid?: string;
  page_path?: string;
  referrer?: string;
}

export function useUtmParams(): UtmParams {
  const [utmParams, setUtmParams] = useState<UtmParams>({
    source: 'Formulário Site PM'
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const trackingParams: UtmParams = {
      source: 'Formulário Site PM',
      page_path: window.location.pathname,
      referrer: document.referrer || undefined
    };

    // Extract UTM parameters
    if (params.has('utm_source')) trackingParams.utm_source = params.get('utm_source') || undefined;
    if (params.has('utm_medium')) trackingParams.utm_medium = params.get('utm_medium') || undefined;
    if (params.has('utm_campaign')) trackingParams.utm_campaign = params.get('utm_campaign') || undefined;
    if (params.has('utm_term')) trackingParams.utm_term = params.get('utm_term') || undefined;
    if (params.has('utm_content')) trackingParams.utm_content = params.get('utm_content') || undefined;
    
    // Extract Google Click ID if present
    if (params.has('gclid')) trackingParams.gclid = params.get('gclid') || undefined;

    // Store in local storage for persistence across pages
    Object.entries(trackingParams).forEach(([key, value]) => {
      if (value && key !== 'page_path' && key !== 'referrer') localStorage.setItem(key, value);
    });

    // Check localStorage for stored UTM parameters (in case they were set on a previous page)
    ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'gclid'].forEach(param => {
      const storedValue = localStorage.getItem(param);
      if (storedValue && !trackingParams[param]) {
        trackingParams[param] = storedValue;
      }
    });

    setUtmParams(trackingParams);
  }, []);

  return utmParams;
}
