
import { useState, useEffect } from "react";
import { RotatingWords } from "./hero/RotatingWords";
import { JudicialForm } from "./hero/JudicialForm";
import { HeroSearch } from "./hero/HeroSearch";
import { BackgroundEffects } from "./hero/BackgroundEffects";

export const SearchHero = () => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [animationsEnabled, setAnimationsEnabled] = useState(false);
  
  const rotatingWords = [
    "Pacientes", 
    "Advogados Especializados", 
    "Defensorias", 
    "Associações", 
    "Médicos", 
    "Convênio Médicos",
    "Farmácias Delivery"
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimationsEnabled(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (!animationsEnabled) return;
    
    const interval = setInterval(() => {
      setCurrentWordIndex(prevIndex => (prevIndex + 1) % rotatingWords.length);
    }, 3000);
    
    return () => clearInterval(interval);
  }, [animationsEnabled, rotatingWords.length]);

  return (
    <div className="w-full min-h-[85vh] relative flex flex-col items-center justify-center px-4 overflow-hidden">
      <BackgroundEffects />
      
      <div className="w-full text-center space-y-6 md:space-y-10 mb-6 md:mb-10 z-20 pt-6 md:pt-10 mt-10">
        <div className="max-w-4xl mx-auto mb-6 md:mb-8 relative z-30">
          <h1 className="font-roboto">
            <RotatingWords 
              words={rotatingWords}
              currentIndex={currentWordIndex}
              animationsEnabled={animationsEnabled}
            />
          </h1>
          
          <div className="max-w-3xl mx-auto text-center mb-4 md:mb-6">
            <h2 className="text-xl md:text-2xl lg:text-3xl text-gray-800 font-medium mt-4 bg-clip-text">
              <span className="text-[#333333]">O primeiro buscador online especializado em cotações de medicamentos especiais</span>
            </h2>
          </div>
          
          <HeroSearch />
        </div>
      </div>
      
      <JudicialForm />
    </div>
  );
};
