type ImageSize = 'thumbnail' | 'small' | 'medium' | 'large' | 'original';
type ImageFormat = 'webp' | 'avif' | 'jpeg' | 'png' | 'original';

interface OptimizeImageOptions {
  size?: ImageSize;
  format?: ImageFormat;
  quality?: number;
  width?: number;
  height?: number;
}

interface SizeConfig {
  width: number;
}

// Define size configurations with explicit types
const sizeMap: Record<ImageSize, SizeConfig | Record<string, never>> = {
  thumbnail: { width: 200 },
  small: { width: 400 },
  medium: { width: 800 },
  large: { width: 1200 },
  original: {}
};

// Helper function to convert PNG URL to WebP
const convertToWebp = (imageUrl: string): string => {
  // Only convert .png urls from our own domain
  if (imageUrl.includes('/lovable-uploads/') && imageUrl.toLowerCase().endsWith('.png')) {
    return imageUrl.replace(/\.png$/i, '.webp');
  }
  return imageUrl;
};

/**
 * Optimize image URL for better performance
 * 
 * @param imageUrl Original image URL
 * @param options Optimization options
 * @returns Optimized image URL
 */
export const optimizeImage = (imageUrl: string, options: OptimizeImageOptions = {}): string => {
  if (!imageUrl) return '';
  
  // Don't optimize data URLs or blob URLs
  if (imageUrl.startsWith('data:') || imageUrl.startsWith('blob:')) {
    return imageUrl;
  }
  
  // For local images (starting with /), we just return the URL directly
  // This ensures the image is loaded correctly from the public directory
  if (imageUrl.startsWith('/')) {
    // Always try to use WebP version first for local images
    if (imageUrl.includes('/lovable-uploads/') && imageUrl.toLowerCase().endsWith('.png')) {
      return imageUrl.replace(/\.png$/i, '.webp');
    }
    return imageUrl;
  }
  
  // First try to convert PNG to WebP for external URLs
  const webpImageUrl = convertToWebp(imageUrl);
  
  // For other URLs, try to use our optimize-image Edge Function or return original
  try {
    // Rest of the optimization logic for Supabase images etc.
    const {
      size = 'medium',
      format = 'webp',
      quality = 80,
      width: customWidth,
      height: customHeight
    } = options;
    
    try {
      // Handle local assets (starting with / or /public/)
      if (webpImageUrl.startsWith('/') && !webpImageUrl.startsWith('//')) {
        // For local images, just return the WebP URL
        return webpImageUrl;
      }
      
      // Fix Supabase storage URLs that start with /storage
      if (webpImageUrl.startsWith('/storage/')) {
        // Get Supabase URL from client
        const supabaseUrl = window.location.origin.includes('localhost') 
          ? import.meta.env.VITE_SUPABASE_URL || 'https://liyitmjjiuhpkzqintfm.supabase.co'
          : window.location.origin;
          
        return `${supabaseUrl}${webpImageUrl}`;
      }
      
      // For Supabase storage URLs
      if (webpImageUrl.includes('supabase.co') || webpImageUrl.includes('storage/v1/object')) {
        const separator = webpImageUrl.includes('?') ? '&' : '?';
        const sizeConfig = sizeMap[size];
        // Safely access width with a fallback
        const sizeWidth = 'width' in sizeConfig ? sizeConfig.width : undefined;
        const finalWidth = customWidth || sizeWidth || 800;
        
        let optimizedUrl = `${webpImageUrl}${separator}width=${finalWidth}&quality=${quality}`;
        
        if (customHeight) {
          optimizedUrl += `&height=${customHeight}`;
        }
        
        // Add format conversion if not original
        if (format !== 'original') {
          optimizedUrl += `&format=${format}`;
        }
        
        return optimizedUrl;
      }
      
      // For other URLs, try to use our optimize-image Edge Function
      if (webpImageUrl.startsWith('http')) {
        const apiUrl = '/api/optimize-image';
        const params = new URLSearchParams();
        params.append('url', encodeURIComponent(webpImageUrl));
        
        const sizeConfig = sizeMap[size];
        const sizeWidth = 'width' in sizeConfig ? sizeConfig.width : undefined;
        params.append('width', String(customWidth || sizeWidth || 800));
        
        if (customHeight) params.append('height', String(customHeight));
        params.append('quality', String(quality));
        params.append('format', format === 'original' ? 'webp' : format);
        
        return `${apiUrl}?${params.toString()}`;
      }
      
      // Fallback to original URL (converted to WebP if possible)
      return webpImageUrl;
    } catch (error) {
      console.error("Error optimizing image:", error);
      return webpImageUrl;
    }
    
    return webpImageUrl;
  } catch (error) {
    console.error("Error optimizing image:", error);
    return webpImageUrl;
  }
};

/**
 * Generate srcset for responsive images
 */
export const generateSrcset = (imageUrl: string, format: ImageFormat = 'webp'): string => {
  if (!imageUrl || imageUrl.startsWith('data:') || imageUrl.startsWith('blob:')) {
    return '';
  }
  
  // Try to convert to WebP first
  const webpImageUrl = convertToWebp(imageUrl);
  
  const widths = [400, 640, 768, 1024, 1280, 1536];
  
  try {
    return widths
      .map(w => `${optimizeImage(webpImageUrl, { width: w, format })} ${w}w`)
      .join(', ');
  } catch (error) {
    console.error("Error generating srcset:", error);
    return '';
  }
};

/**
 * Create an optimized image component props
 */
export const getOptimizedImageProps = (
  src: string,
  alt: string,
  className?: string,
  sizes: string = '100vw'
) => {
  // Convert to WebP if possible
  const webpSrc = convertToWebp(src);
  
  return {
    src: optimizeImage(webpSrc, { format: 'webp' }),
    alt,
    loading: 'lazy' as const,
    decoding: 'async' as const,
    srcSet: generateSrcset(webpSrc),
    sizes,
    className,
    onError: (e: React.SyntheticEvent<HTMLImageElement>) => {
      // Fallback to original image on error
      const img = e.currentTarget;
      if (img.src !== webpSrc) {
        img.src = webpSrc;
      }
      if (img.src !== src) {
        img.src = src;
      }
    }
  };
};
