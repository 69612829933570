import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { ChevronDown, Pill, X, Search } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useToast } from "@/hooks/use-toast";
import { MedicationCategory } from "@/types/medication";
import { Input } from "@/components/ui/input";

export function CategoryMenu() {
  const [categories, setCategories] = useState<MedicationCategory[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const { data, error } = await supabase
          .from("medication_categories")
          .select("*")
          .order("name");

        if (error) throw error;
        setCategories(data || []);
      } catch (err: any) {
        console.error("Erro ao carregar categorias:", err.message);
        toast({
          title: "Erro ao carregar categorias",
          description: "Não foi possível carregar as categorias de medicamentos.",
          variant: "destructive"
        });
      } finally {
        setIsLoading(false);
      }
    };

    fetchCategories();
  }, [toast]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleCategoryClick = (categoryId: string) => {
    console.log("Category clicked:", categoryId);
    navigate(`/medicamentos?category=${categoryId}`);
    setIsOpen(false);
  };

  const filteredCategories = categories.filter(category => 
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const categoryGroups = [
    {
      title: "Medicamentos Especiais",
      items: filteredCategories.slice(0, Math.ceil(filteredCategories.length / 3))
    },
    {
      title: "Condições e Doenças",
      items: filteredCategories.slice(
        Math.ceil(filteredCategories.length / 3),
        Math.ceil(filteredCategories.length / 3) * 2
      )
    },
    {
      title: "Princípios Ativos",
      items: filteredCategories.slice(Math.ceil(filteredCategories.length / 3) * 2)
    }
  ];

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center gap-2 bg-transparent text-gray-700 px-4 py-2 rounded-md hover:bg-primary hover:text-white transition-all duration-300 group"
      >
        <Pill className="h-5 w-5 group-hover:rotate-12 transition-transform" />
        <span className="font-medium">Categorias</span>
        <ChevronDown 
          className={`h-4 w-4 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`} 
        />
      </button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
            className="fixed mt-2 w-screen max-w-4xl bg-white rounded-lg shadow-xl border border-gray-100 z-[9999999] overflow-hidden"
            style={{ 
              top: dropdownRef.current ? 
                dropdownRef.current.getBoundingClientRect().bottom + 2 : 0,
              left: dropdownRef.current ? 
                dropdownRef.current.getBoundingClientRect().left : 0,
              maxHeight: 'calc(100vh - 200px)' 
            }}
          >
            <div className="p-4 bg-gradient-to-r from-blue-50 to-purple-50 border-b border-gray-100 flex items-center justify-between">
              <h3 className="text-lg font-semibold text-primary">Categorias de Medicamentos</h3>
              <button 
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="p-4 border-b border-gray-100 bg-gray-50">
              <div className="relative">
                <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-4 w-4" />
                <Input
                  type="text"
                  placeholder="Buscar categorias..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="pl-10 bg-white"
                />
              </div>
            </div>

            <div className="overflow-auto" style={{ maxHeight: 'calc(100vh - 280px)' }}>
              {isLoading ? (
                <div className="p-8 text-center">
                  <div className="animate-spin h-6 w-6 border-2 border-primary border-t-transparent rounded-full mx-auto"></div>
                  <p className="mt-2 text-gray-500">Carregando categorias...</p>
                </div>
              ) : filteredCategories.length === 0 ? (
                <div className="p-8 text-center text-gray-500">
                  <p>Nenhuma categoria encontrada com "{searchTerm}"</p>
                </div>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 p-6">
                  {categoryGroups.map((group, index) => (
                    <div key={index} className="space-y-3">
                      <h4 className="font-medium text-gray-800 border-b border-gray-200 pb-2">{group.title}</h4>
                      <ul className="space-y-2">
                        {group.items.map(category => (
                          <li key={category.id}>
                            <button
                              onClick={() => handleCategoryClick(category.id)}
                              className="text-gray-700 hover:text-primary transition-colors w-full text-left flex items-center group"
                            >
                              <span className="w-1.5 h-1.5 rounded-full bg-primary/70 mr-2 group-hover:scale-150 transition-transform"></span>
                              {category.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
