
import { FormAlerts } from "./form/FormAlerts";
import { FormFields } from "./form/FormFields";
import { JudicialFormHeader } from "./JudicialFormHeader";
import { SubmitButton } from "./form/SubmitButton";
import { useJudicialForm } from "./form/useJudicialForm";

export const JudicialForm = () => {
  const {
    formData,
    isSubmitting,
    showSuccessAlert,
    submitError,
    handleChange,
    handleSubmit
  } = useJudicialForm();

  return (
    <div className="max-w-4xl mx-auto bg-white/90 backdrop-blur-sm rounded-xl shadow-lg border border-gray-100 overflow-hidden z-10 w-full mb-10 animate-fade-in hover:shadow-xl transition-shadow duration-300">
      <FormAlerts 
        showSuccessAlert={showSuccessAlert} 
        submitError={submitError} 
      />
      
      <JudicialFormHeader />
      
      <div className="p-4 md:p-6 lg:p-8">
        <form onSubmit={handleSubmit} className="space-y-4">
          <FormFields 
            formData={formData} 
            handleChange={handleChange} 
          />
          
          <SubmitButton isSubmitting={isSubmitting} />
        </form>
      </div>
    </div>
  );
};
