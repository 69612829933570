
/**
 * Utility to suppress console logs in production
 */

// Determine if we should suppress logs based on environment and URL params
export const shouldSuppressLogs = (): boolean => {
  // Always show logs in development for debugging
  if (process.env.NODE_ENV === 'development') {
    return false;
  }
  
  // Check for debug parameter in URL for authorized users
  const urlParams = new URLSearchParams(window.location.search);
  const debugMode = urlParams.get('debug_mode');
  
  // Only show logs if debug mode is specifically enabled with a valid key
  // This would typically be a hash or token that only developers know
  return debugMode !== 'authorized_debug_f8a72c';
};

/**
 * Suppress all console output in production
 */
export const suppressConsoleOutput = (): void => {
  if (!shouldSuppressLogs()) {
    return; // Don't suppress in development or when debug mode is enabled
  }
  
  // Store original console methods
  const originalConsole = {
    log: console.log,
    info: console.info,
    warn: console.warn,
    error: console.error,
    debug: console.debug,
  };

  // Replace with empty functions for production
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.debug = () => {};
  
  // Keep error logging but sanitize the output
  console.error = (...args) => {
    // Only log minimal information for genuine errors
    // This prevents exposing stack traces but keeps error notifications
    const sanitizedArgs = args.map(arg => {
      if (arg instanceof Error) {
        return `Error occurred`;
      }
      if (typeof arg === 'string') {
        // Remove any sensitive info, paths, or identifiable platform details
        return arg.replace(/(lovable|supabase|api_key|token|password|secret)/gi, '[redacted]');
      }
      return 'Error object';
    });
    
    // Call original with sanitized arguments
    originalConsole.error(...sanitizedArgs);
  };
  
  // Disable console clearing to prevent detection of console overrides
  console.clear = () => {
    originalConsole.log('Console clear attempted');
  };
};

/**
 * Initialize console protection on page load
 */
export const initConsoleProtection = (): void => {
  suppressConsoleOutput();
  
  // Allow context menu for form elements where it might be needed
  document.addEventListener('contextmenu', (e) => {
    const target = e.target as HTMLElement;
    const isFormElement = 
      target.tagName === 'INPUT' || 
      target.tagName === 'TEXTAREA' || 
      target.tagName === 'SELECT';
    
    if (!isFormElement && shouldSuppressLogs()) {
      e.preventDefault();
    }
  });
  
  // Fixed: Don't block mobile users with false dev tools detection
  if (shouldSuppressLogs()) {
    // Skip developer tools detection on mobile devices
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    if (!isMobile) {
      // Only apply dev tools detection on desktop browsers
      const devToolsDetection = () => {
        // Adjust thresholds to avoid false positives
        // Most mobile browsers have different window measurements that can trigger false positives
        const widthThreshold = window.outerWidth - window.innerWidth > 200;
        const heightThreshold = window.outerHeight - window.innerHeight > 200;
        
        if (widthThreshold && heightThreshold) {
          // Only show maintenance message if both thresholds are exceeded
          // This reduces false positives significantly
          document.body.innerHTML = '<div style="text-align:center;padding:50px;">Site em manutenção. Por favor, tente novamente mais tarde.</div>';
        }
      };
      
      // Run detection less frequently to reduce performance impact
      window.addEventListener('resize', devToolsDetection);
      const checkInterval = setInterval(devToolsDetection, 5000);
      
      // Clean up interval if the page changes
      window.addEventListener('beforeunload', () => {
        clearInterval(checkInterval);
      });
    }
  }
};
