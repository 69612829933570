
export const BackgroundEffects = () => {
  return (
    <>
      <div className="absolute inset-0 bg-gradient-to-b from-blue-50 to-white -z-10"></div>
      <div className="absolute top-0 left-0 right-0 h-64 bg-[radial-gradient(ellipse_at_top,rgba(0,141,213,0.2),transparent_70%)] -z-5"></div>
      <div className="absolute -top-24 -right-24 w-96 h-96 bg-[radial-gradient(circle,rgba(60,177,241,0.1),transparent_70%)] rounded-full blur-3xl"></div>
      <div className="absolute -bottom-32 -left-32 w-96 h-96 bg-[radial-gradient(circle,rgba(0,141,213,0.1),transparent_70%)] rounded-full blur-3xl"></div>
    </>
  );
};
