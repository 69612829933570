
import { useState, useEffect, useRef, lazy, Suspense } from "react";
import { Navbar } from "@/components/Navbar";
import { SearchHero } from "@/components/SearchHero";
import { Footer } from "@/components/Footer";
import { FAQ } from "@/components/FAQ";
import { TrendingMedications } from "@/components/TrendingMedications";
import { FeatureSection } from "@/components/sections/FeatureSection";
import { HowItWorksSection } from "@/components/sections/HowItWorksSection";
import { RecentBlogPosts } from "@/components/sections/RecentBlogPosts";
import { CTASection } from "@/components/sections/CTASection";
import { useIntersectionObserver } from "@/utils/lazyLoadUtils";
import { PlatformStats } from "@/components/PlatformStats";
import { WhatsAppButton } from "@/components/WhatsAppButton";
// Removed WelcomePopup import

const TestimonialSection = lazy(() => import("@/components/TestimonialSection"));

const Index = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  
  const testimonialSectionRef = useRef<HTMLDivElement>(null);
  const ctaSectionRef = useRef<HTMLDivElement>(null);
  
  const testimonialObserver = useIntersectionObserver({
    threshold: 0,
    rootMargin: '200px'
  });
  
  const ctaSectionObserver = useIntersectionObserver({
    threshold: 0,
    rootMargin: '200px'
  });

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const docHeight = document.body.offsetHeight - window.innerHeight;
      const scrollPercent = scrollTop / docHeight;
      setScrollProgress(scrollPercent);
    };
    
    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="min-h-screen bg-white dark:bg-gray-900 relative">
      {/* Removed WelcomePopup component */}
      
      {/* Background gradient that extends behind navbar */}
      <div className="hero-background absolute top-0 left-0 right-0 h-[100vh] z-0"></div>
      
      <div 
        className="fixed top-0 left-0 h-1 bg-gradient-to-r from-primary via-accent to-primary-light z-50" 
        style={{ width: `${scrollProgress * 100}%` }} 
      />
      
      <Navbar />
      
      <main className="relative z-10">
        <SearchHero />
        
        <TrendingMedications />
        
        <PlatformStats />
        
        <FeatureSection />
        
        <HowItWorksSection />
        
        <div ref={testimonialObserver.ref}>
          {testimonialObserver.isVisible && (
            <Suspense fallback={null}>
              <TestimonialSection />
            </Suspense>
          )}
        </div>
        
        <RecentBlogPosts />
        
        <FAQ />
        
        <div ref={ctaSectionObserver.ref}>
          {ctaSectionObserver.isVisible && (
            <CTASection />
          )}
        </div>
      </main>
      
      <Footer />
      <WhatsAppButton />
    </div>
  );
};

export default Index;
