
import { MessageCircle, Phone } from "lucide-react";

export const JudicialFormHeader = () => {
  const handleWhatsAppClick = () => {
    window.open("https://bit.ly/digisacpm", "_blank");
  };

  return (
    <>
      <div className="bg-gradient-to-r from-primary to-primary-dark text-white p-4 md:p-6 relative overflow-hidden">
        <div className="absolute -right-12 -top-12 w-40 h-40 bg-white/10 rounded-full"></div>
        <div className="absolute -right-6 -bottom-6 w-24 h-24 bg-white/5 rounded-full"></div>
        
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between relative z-10 gap-3 md:gap-0">
          <div>
            <h2 className="text-xl md:text-2xl lg:text-3xl font-bold mb-1 md:mb-2 font-roboto leading-tight">Receba cotações para processos de medicamentos</h2>
            <p className="text-white/90 font-roboto text-sm md:text-base">Solicite aqui suas 3 cotações</p>
          </div>
          <div className="hidden md:block">
            <div className="bg-white/20 backdrop-blur-md p-3 rounded-lg">
              <p className="text-white text-sm">Economize tempo e recursos com nossa equipe especializada</p>
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex flex-col sm:flex-row flex-wrap items-center gap-2 mb-4 md:mb-6 bg-primary/10 p-3 rounded-lg hover:bg-primary/15 transition-colors">
        <div className="flex gap-2 items-center w-full sm:w-auto justify-center sm:justify-start">
          <MessageCircle className="h-5 w-5 text-primary animate-pulse" />
          <span className="font-roboto text-sm md:text-base">Qualquer dúvida nos chame no chat</span>
        </div>
        <div className="mx-2 text-gray-400 hidden sm:block">ou</div>
        <div className="flex gap-2 items-center w-full sm:w-auto justify-center sm:justify-start mt-2 sm:mt-0">
          <Phone className="h-5 w-5 text-primary" />
          <span className="font-roboto text-sm md:text-base">Ligue <strong className="font-open-sans">0800 557 7000</strong> gratuitamente</span>
        </div>
      </div>
    </>
  );
};
