
import { Menu, Search, User, MessageCircle } from "lucide-react";
import { useState, useEffect } from "react";
import { Button } from "./ui/button";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { getCurrentUser, logoutUser, isAdminUser } from "@/utils/auth";
import { MobileMenu } from "./MobileMenu";
import { useNavigate } from "react-router-dom";
import { CategoryMenu } from "./CategoryMenu";
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger, navigationMenuTriggerStyle } from "@/components/ui/navigation-menu";

export const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const {
    toast
  } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 10;
      if (isScrolled !== scrolled) {
        setScrolled(isScrolled);
      }
    };
    window.addEventListener('scroll', handleScroll, {
      passive: true
    });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolled]);

  useEffect(() => {
    const checkUser = async () => {
      const user = await getCurrentUser();
      console.log("Current user in Navbar:", user);
      setCurrentUser(user);
    };
    checkUser();
    const {
      data: authListener
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log("Auth state changed:", event, session?.user?.id);
      if (event === 'SIGNED_IN' && session) {
        const {
          data: profileData
        } = await supabase.from('profiles').select('*').eq('id', session.user.id).maybeSingle();
        console.log("Profile data after signin:", profileData);
        const userData = {
          ...session.user,
          role: profileData?.role || null,
          name: profileData?.name || null
        };
        setCurrentUser(userData);
        console.log("Auth state changed: SIGNED_IN", userData);
        toast({
          title: "Login realizado com sucesso",
          description: "Bem-vindo de volta!"
        });
        if (profileData && (profileData.role === "administrador" || profileData.role === "administrador_master")) {
          console.log("Admin user detected, redirecting to dashboard");
          navigate("/admin/dashboard");
        }
      } else if (event === 'SIGNED_OUT') {
        console.log("User signed out, clearing current user");
        setCurrentUser(null);
        toast({
          title: "Logout realizado",
          description: "Você foi desconectado com sucesso."
        });
        navigate("/");
      }
    });
    return () => {
      if (authListener && authListener.subscription) {
        console.log("Unsubscribing from auth state changes");
        authListener.subscription.unsubscribe();
      }
    };
  }, [toast, navigate]);

  const handleLogout = async () => {
    try {
      setIsLoading(true);
      console.log("Attempting to logout user");
      await logoutUser();
      toast({
        title: "Logout realizado com sucesso",
        description: "Você foi desconectado."
      });
      console.log("Logout successful, clearing current user");
      setCurrentUser(null);
      navigate("/");
    } catch (error: any) {
      console.error("Logout error:", error);
      toast({
        title: "Erro ao fazer logout",
        description: error.message || "Ocorreu um erro ao fazer logout. Por favor, tente novamente.",
        variant: "destructive"
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAdminDashboardClick = () => {
    console.log("Navigate to admin dashboard clicked");
    navigate("/admin/dashboard", {
      replace: true
    });
  };

  const isAdminOrHasAccess = (user: any) => {
    return user && (user.role === "administrador" || user.role === "administrador_master" || user.role === "juridico" || user.role === "sdr" || user.role === "parceiro_fornecedor" || user.role === "marketing");
  };

  const menuItemClass = "flex items-center gap-2 bg-transparent text-gray-700 px-4 py-2 rounded-md hover:bg-primary hover:text-white transition-all duration-300 font-medium";

  return <nav className={`fixed top-0 left-0 right-0 h-16 z-50 transition-all duration-300 ${scrolled ? 'bg-white/90 backdrop-blur-lg border-b border-gray-100' : 'bg-transparent'}`}>
      <div className="container h-full flex items-center justify-between">
        <a href="/" className="flex items-center">
          <img 
            src="/lovable-uploads/00db7f97-33a2-4007-a490-06f3145c2c2a.png" 
            alt="Preço Medicamentos" 
            className="h-10 mr-2 transition-transform duration-300 hover:scale-105"
            width="auto"
            height="40"
            loading="eager"
            fetchPriority="high"
          />
        </a>
        
        <div className="hidden md:flex items-center justify-center flex-1">
          <div className="flex items-center justify-center space-x-10">
            <CategoryMenu />
            <a href="/medicamentos" className={menuItemClass}>
              Medicamentos
            </a>
            
            <a href="/blog" className={menuItemClass}>
              Blog
            </a>
          </div>
        </div>

        <div className="hidden md:flex items-center space-x-4">
          {currentUser ? <div className="flex items-center space-x-2">
              {isAdminOrHasAccess(currentUser) && <Button variant="outline" size="sm" onClick={handleAdminDashboardClick}>
                  <User className="h-5 w-5 mr-1" />
                  <span>Minha Conta</span>
                </Button>}
              <Button variant="ghost" size="sm" onClick={handleLogout} disabled={isLoading}>
                <span>{isLoading ? "Saindo..." : "Sair"}</span>
              </Button>
            </div> : null}
          <Button 
            className="bg-primary text-white hover:bg-primary/90"
            onClick={() => window.open("https://bit.ly/digisacpm", "_blank")}
          >
            <MessageCircle className="h-5 w-5 mr-1" />
            <span>Fale Conosco </span>
          </Button>
        </div>

        <button className={`md:hidden p-2 rounded-lg transition-colors ${scrolled ? 'hover:bg-gray-100' : 'hover:bg-blue-100/50'}`} onClick={() => setMenuOpen(!menuOpen)}>
          <Menu className={`h-6 w-6 ${!scrolled ? 'text-gray-800' : 'text-gray-800'}`} />
        </button>
      </div>

      {menuOpen && <MobileMenu currentUser={currentUser} isLoading={isLoading} onLoginClick={() => navigate("/login")} onLogoutClick={handleLogout} />}
    </nav>;
};
