
import { useState, useEffect, useRef } from "react";
import { Search } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useAutocompleteSearch, MedicationSuggestion } from "@/hooks/useAutocompleteSearch";

export const SearchBox = ({ 
  onSearch, 
  initialSearchTerm = "", 
  className = "",
  showSuggestions = true
}: { 
  onSearch?: (term: string) => void, 
  initialSearchTerm?: string,
  className?: string,
  showSuggestions?: boolean
}) => {
  
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [showSuggestionsDropdown, setShowSuggestionsDropdown] = useState(false);
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  
  const { 
    suggestions, 
    isLoading, 
    error, 
    setSearchTerm: setAutocompleteTerm,
    clearSuggestions
  } = useAutocompleteSearch();

  useEffect(() => {
    setSearchTerm(initialSearchTerm);
  }, [initialSearchTerm]);
  
  useEffect(() => {
    setAutocompleteTerm(searchTerm);
  }, [searchTerm, setAutocompleteTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current && 
        !suggestionsRef.current.contains(event.target as Node) &&
        inputRef.current && 
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestionsDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    if (onSearch) {
      onSearch(searchTerm);
    } else {
      navigate(`/medicamentos?q=${encodeURIComponent(searchTerm)}`);
    }
    setShowSuggestionsDropdown(false);
    clearSuggestions();
  };
  
  const handleSuggestionClick = (suggestion: MedicationSuggestion) => {
    if (suggestion.slug) {
      navigate(`/medicamentos/${suggestion.slug}`);
    } else {
      navigate(`/medicamentos/${suggestion.id}`);
    }
    setShowSuggestionsDropdown(false);
    clearSuggestions();
  };
  
  const handleInputFocus = () => {
    if (showSuggestions) {
      if (searchTerm.length >= 2) {
        setAutocompleteTerm("");
        setTimeout(() => {
          setAutocompleteTerm(searchTerm);
        }, 50);
      }
      setShowSuggestionsDropdown(true);
    }
  };

  return (
    <div ref={containerRef} className={`w-full max-w-4xl mx-auto relative ${className}`} style={{ position: 'relative', zIndex: 1000 }}>
      <form onSubmit={handleSubmit} className="w-full relative">
        <div className="relative flex items-center shadow-lg rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl w-full">
          <div className="absolute inset-0 bg-gradient-to-r from-primary/10 to-primary/5 z-0"></div>
          <div className="absolute inset-0 border-2 border-primary/20 rounded-lg z-0"></div>
          
          <Input
            ref={inputRef}
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={handleInputFocus}
            placeholder="Digite o nome do medicamento ou princípio ativo..."
            className="pr-32 pl-6 py-7 h-16 text-lg md:text-xl w-full rounded-lg border-0 bg-white/80 backdrop-blur-sm focus-visible:ring-2 focus-visible:ring-primary/50 text-[#221F26] z-10 font-roboto transition-all placeholder:text-gray-500 placeholder:font-normal"
          />
          
          <Button 
            type="submit" 
            className="absolute right-3 bg-primary hover:bg-primary-dark text-white font-medium py-2 h-12 px-6 rounded-md z-10 flex items-center gap-2 transition-all duration-300 hover:translate-x-1"
          >
            <Search className="w-5 h-5" />
            <span>Buscar</span>
          </Button>
        </div>
      </form>
      
      {showSuggestions && showSuggestionsDropdown && (
        <div 
          ref={suggestionsRef}
          className="search-suggestions-dropdown absolute left-0 w-full bg-white rounded-md shadow-xl border border-gray-200 max-h-80 overflow-y-auto mt-2 animate-fade-in"
          style={{ 
            width: inputRef.current ? inputRef.current.offsetWidth : '100%',
            zIndex: 10000,
            position: 'absolute'
          }}
        >
          {isLoading ? (
            <div className="p-3 text-center text-gray-500">
              <div className="animate-spin w-5 h-5 border-2 border-primary border-t-transparent rounded-full mx-auto"></div>
              <p className="mt-1">Buscando...</p>
            </div>
          ) : error ? (
            <div className="p-3 text-center text-red-500">{error}</div>
          ) : suggestions.length > 0 ? (
            <ul>
              {suggestions.map((suggestion) => (
                <li 
                  key={suggestion.id}
                  onClick={() => handleSuggestionClick(suggestion)}
                  className="px-6 py-4 hover:bg-gray-50 cursor-pointer border-b border-gray-100 last:border-0 transition-colors"
                >
                  <div className="font-medium text-[#221F26] text-lg">{suggestion.name}</div>
                  
                  {suggestion.active_ingredient && (
                    <div className="text-sm text-gray-600 mt-1">
                      Princípio Ativo: <span className="font-medium">{suggestion.active_ingredient}</span>
                    </div>
                  )}
                  
                  {suggestion.price !== null && (
                    <div className="text-sm text-green-600 font-medium mt-1">
                      R$ {suggestion.price.toFixed(2).replace('.', ',')}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          ) : searchTerm.length >= 2 ? (
            <div className="p-6 text-center text-gray-500">
              Nenhum medicamento encontrado
            </div>
          ) : (
            <div className="p-6 text-center text-gray-500">
              Digite pelo menos 2 caracteres para buscar
            </div>
          )}
        </div>
      )}
    </div>
  );
};
