
import { createContext, useContext, ReactNode } from "react";
import { toast } from "@/hooks/use-toast";
import { AlertCircle, CheckCircle, Info, AlertTriangle } from "lucide-react";

type AlertType = "success" | "error" | "warning" | "info";

interface AlertContextProps {
  showAlert: (type: AlertType, title: string, message?: string) => void;
  showConfirmation: (title: string, message: string, onConfirm: () => void, onCancel?: () => void) => void;
}

const AlertContext = createContext<AlertContextProps | undefined>(undefined);

export function AlertProvider({ children }: { children: ReactNode }) {
  const showAlert = (type: AlertType, title: string, message?: string) => {
    const icon = {
      success: <CheckCircle className="h-5 w-5" />,
      error: <AlertCircle className="h-5 w-5" />,
      warning: <AlertTriangle className="h-5 w-5" />,
      info: <Info className="h-5 w-5" />
    }[type];

    toast({
      variant: type as any,
      title: (
        <div className="flex items-center gap-2">
          {icon}
          <span>{title}</span>
        </div>
      ) as any, // Cast to any to avoid type error
      description: message,
    });
  };

  const showConfirmation = (
    title: string, 
    message: string, 
    onConfirm: () => void, 
    onCancel?: () => void
  ) => {
    // This function will be handled by the DeleteConfirmationDialog component
    console.log("showConfirmation called with:", { title, message });
    // Implementaremos a lógica de confirmação diretamente nos componentes que precisam dela
  };

  return (
    <AlertContext.Provider value={{ showAlert, showConfirmation }}>
      {children}
    </AlertContext.Provider>
  );
}

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};
