
/**
 * Utilitário para traduzir mensagens de erro do Supabase para português
 */

type ErrorTranslation = {
  [key: string]: string;
};

// Mapeamento de mensagens de erro do Supabase para português
const AUTH_ERROR_TRANSLATIONS: ErrorTranslation = {
  "Invalid login credentials": "Credenciais de login inválidas",
  "Email not confirmed": "Email não confirmado. Por favor, verifique sua caixa de entrada",
  "User already registered": "Usuário já registrado",
  "New password should be different from the old password": "A nova senha deve ser diferente da senha antiga",
  "Password recovery requires an email": "Recuperação de senha requer um email",
  "Email link is invalid or has expired": "O link de email é inválido ou expirou",
  "Token has expired or is invalid": "O token expirou ou é inválido",
  "Invalid or expired password reset token": "Token de redefinição de senha inválido ou expirado",
  "Password should be at least 6 characters": "A senha deve ter pelo menos 6 caracteres",
  "Server error. Try again later.": "Erro no servidor. Tente novamente mais tarde.",
  "For security purposes, you can only request this once every 60 seconds": 
    "Por motivos de segurança, você só pode solicitar isso uma vez a cada 60 segundos",
  "Email rate limit exceeded": "Limite de envio de emails excedido. Tente novamente mais tarde.",
  "Password must be at least 6 characters": "A senha deve ter pelo menos 6 caracteres",
  "Please enter an email address": "Por favor, insira um email",
  "Please enter your password": "Por favor, digite sua senha",
};

// Mensagens de erro gerais da aplicação
const GENERAL_ERROR_TRANSLATIONS: ErrorTranslation = {
  "An unexpected error occurred": "Ocorreu um erro inesperado",
  "Network error": "Erro de conexão. Verifique sua internet",
  "Session expired": "Sua sessão expirou. Por favor, faça login novamente",
};

/**
 * Traduz uma mensagem de erro para português
 * @param errorMessage A mensagem de erro original
 * @returns A mensagem traduzida ou a original caso não tenha tradução
 */
export const translateError = (errorMessage: string): string => {
  // Primeiro tenta encontrar nas mensagens de autenticação
  if (AUTH_ERROR_TRANSLATIONS[errorMessage]) {
    return AUTH_ERROR_TRANSLATIONS[errorMessage];
  }
  
  // Depois tenta nas mensagens gerais
  if (GENERAL_ERROR_TRANSLATIONS[errorMessage]) {
    return GENERAL_ERROR_TRANSLATIONS[errorMessage];
  }
  
  // Verifica se a mensagem contém texto conhecido
  for (const key of Object.keys(AUTH_ERROR_TRANSLATIONS)) {
    if (errorMessage.includes(key)) {
      return AUTH_ERROR_TRANSLATIONS[key];
    }
  }
  
  // Retorna a mensagem original se não encontrar tradução
  console.log("Mensagem de erro sem tradução:", errorMessage);
  return errorMessage;
};

/**
 * Extrai a mensagem de erro mais relevante de um objeto de erro
 * @param error O objeto de erro
 * @returns A mensagem de erro mais relevante
 */
export const extractErrorMessage = (error: any): string => {
  if (!error) {
    return "Ocorreu um erro desconhecido";
  }
  
  // Tenta diferentes propriedades de erro comuns
  const message = 
    error.message || 
    error.error_description || 
    error.error || 
    (typeof error === 'string' ? error : "Ocorreu um erro desconhecido");
  
  return translateError(message);
};
