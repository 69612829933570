
import { MessageCircle, BookOpen } from "lucide-react";
import { Button } from "@/components/ui/button";
import { trackButtonClick, trackEvent } from "@/utils/trackingUtils";
import { useUtmParams } from "@/hooks/useUtmParams";

export function CTASection() {
  const utmParams = useUtmParams();

  const handleFAQClick = (e: React.MouseEvent) => {
    e.preventDefault();
    
    trackEvent({
      category: 'Navigation',
      action: 'Scroll',
      label: 'FAQ Section',
      utmParams
    });
    
    const faqSection = document.getElementById("perguntas-frequentes");
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleWhatsAppClick = () => {
    trackButtonClick(
      'CTA Section WhatsApp',
      'whatsapp',
      'https://bit.ly/digisacpm',
      utmParams
    );
    window.open("https://bit.ly/digisacpm", "_blank");
  };

  // Already using WebP image format
  const backgroundImageUrl = "/lovable-uploads/a542c729-4fea-45ba-a8ae-14733181c636.webp";

  return (
    <section className="py-24 relative overflow-hidden">
      <div className="absolute inset-0 bg-gradient-to-r from-primary via-primary-dark to-primary z-0"></div>
      <div 
        className="absolute inset-0 opacity-10 bg-cover bg-center"
        style={{ backgroundImage: `url('${backgroundImageUrl}')` }}
        aria-hidden="true"
      ></div>
      
      <div className="container relative z-10">
        <div className="max-w-4xl mx-auto text-center text-white">
          <h2 className="text-3xl md:text-5xl font-bold mb-6 [text-shadow:0_2px_10px_rgba(0,0,0,0.1)]">
            Não encontrou o medicamento que procura?
          </h2>
          <p className="text-xl mb-10 text-white/90 max-w-3xl mx-auto">
            Entre em contato com nossa equipe de especialistas. Faremos o possível para ajudar você a encontrar o que precisa, com as melhores condições do mercado.
          </p>
          <div className="flex flex-col sm:flex-row justify-center gap-4">
            <Button 
              size="lg" 
              variant="secondary" 
              className="bg-white text-primary hover:bg-white/90 transition-all border-0 shadow-lg hover:shadow-xl"
              onClick={handleWhatsAppClick}
            >
              <MessageCircle className="mr-2 h-5 w-5" />
              Falar com Especialista
            </Button>
            <Button 
              size="lg" 
              variant="outline" 
              className="bg-transparent border-2 border-white text-white hover:bg-white/10 transition-all"
              onClick={handleFAQClick}
            >
              <BookOpen className="mr-2 h-5 w-5" />
              Ver Perguntas Frequentes
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}
