
import { ReactNode } from "react";

interface GlassCardProps {
  children: ReactNode;
  className?: string;
  hoverEffect?: boolean;
}

export function GlassCard({ children, className = "", hoverEffect = true }: GlassCardProps) {
  return (
    <div 
      className={`
        relative bg-white/70 dark:bg-gray-900/60 backdrop-blur-lg 
        border border-white/20 dark:border-white/10 
        rounded-xl shadow-xl overflow-hidden
        ${hoverEffect ? 'transition-all duration-300 hover:shadow-primary/20 hover:border-primary/30 hover:-translate-y-1' : ''}
        ${className}
      `}
    >
      <div className="absolute inset-0 bg-gradient-to-br from-primary/5 to-transparent pointer-events-none" />
      <div className="relative z-10">
        {children}
      </div>
    </div>
  );
}
