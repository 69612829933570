
import React from "react";
import { AlertCircle, RefreshCw, Bug, Home } from "lucide-react";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { 
      hasError: false, 
      error: null,
      errorInfo: null 
    };
    this.handleRetry = this.handleRetry.bind(this);
    this.handleGoHome = this.handleGoHome.bind(this);
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state so the next render will show the fallback UI
    return { 
      hasError: true, 
      error,
      errorInfo: null
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // Log the error to an error reporting service
    console.error("Erro capturado no ErrorBoundary:", error, errorInfo);
    this.setState({
      errorInfo
    });
  }

  handleRetry() {
    // Clear the error state and try to re-render the component
    this.setState({ 
      hasError: false,
      error: null,
      errorInfo: null
    });
    
    // Force refresh the page if it's a module loading error or suspense error
    const moduleError = this.state.error?.message?.includes('Failed to fetch dynamically imported module') ||
                       this.state.error?.message?.includes('Loading chunk') ||
                       this.state.error?.message?.includes('React error #426') ||
                       this.state.error?.message?.includes('component suspended');
    if (moduleError) {
      console.log("Detectado erro de carregamento de módulo ou suspense, recarregando a página");
      window.location.reload();
    }
  }

  handleGoHome() {
    // Navigate to home page
    window.location.href = '/';
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      // If a fallback UI was provided, use that
      if (this.props.fallback) {
        return this.props.fallback;
      }

      // Check if it's a module loading error or a suspense error
      const moduleError = this.state.error?.message?.includes('Failed to fetch dynamically imported module') || 
                         this.state.error?.message?.includes('Loading chunk') ||
                         this.state.error?.message?.includes('React error #426') ||
                         this.state.error?.message?.includes('component suspended');
      
      const networkError = this.state.error?.message?.includes('NetworkError') || 
                          this.state.error?.message?.includes('network') ||
                          this.state.error?.message?.includes('fetch');

      // Custom title and message based on error type
      let errorTitle = "Erro ao carregar a página";
      let errorMessage = this.state.error?.message || "Ocorreu um erro inesperado.";

      if (moduleError) {
        errorTitle = "Erro ao carregar componente";
        errorMessage = "Um componente não conseguiu ser carregado corretamente. Isso pode acontecer devido a problemas de rede ou modo de renderização.";
      } else if (networkError) {
        errorTitle = "Problema de conexão detectado";
        errorMessage = "Verifique sua conexão de internet e tente novamente.";
      }

      return (
        <div className="w-full max-w-md mx-auto p-4 rounded-lg shadow bg-white dark:bg-gray-800 my-6">
          <div className="flex items-center justify-center w-full mb-4">
            {moduleError ? (
              <div className="h-12 w-12 rounded-full bg-amber-100 flex items-center justify-center">
                <RefreshCw className="h-6 w-6 text-amber-600" />
              </div>
            ) : networkError ? (
              <div className="h-12 w-12 rounded-full bg-blue-100 flex items-center justify-center">
                <AlertCircle className="h-6 w-6 text-blue-600" />
              </div>
            ) : (
              <div className="h-12 w-12 rounded-full bg-red-100 flex items-center justify-center">
                <Bug className="h-6 w-6 text-red-600" />
              </div>
            )}
          </div>
          
          <h2 className="text-xl font-semibold text-center mb-2">
            {errorTitle}
          </h2>
          
          <p className="text-gray-600 dark:text-gray-300 text-center mb-4 text-sm">
            {errorMessage}
          </p>
          
          <div className="flex flex-col gap-3 mt-6">
            <Button 
              variant="default" 
              onClick={this.handleRetry} 
              className="w-full flex items-center justify-center gap-2"
            >
              <RefreshCw className="h-4 w-4" />
              <span>Tentar novamente</span>
            </Button>
            
            <Button 
              variant="outline" 
              onClick={this.handleGoHome}
              className="w-full flex items-center justify-center gap-2"
            >
              <Home className="h-4 w-4" />
              <span>Voltar à página inicial</span>
            </Button>
          </div>
          
          {this.state.error && (
            <div className="mt-6 pt-4 border-t border-gray-200 dark:border-gray-700">
              <details className="text-xs text-gray-500 dark:text-gray-400">
                <summary className="cursor-pointer">Detalhes técnicos</summary>
                <pre className="mt-2 p-2 bg-gray-100 dark:bg-gray-900 rounded overflow-x-auto">
                  {this.state.error.toString()}
                </pre>
              </details>
            </div>
          )}
        </div>
      );
    }

    return this.props.children;
  }
}
