// This file is automatically generated. Do not edit it directly.
import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const SUPABASE_URL = "https://liyitmjjiuhpkzqintfm.supabase.co";
const SUPABASE_PUBLISHABLE_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxpeWl0bWpqaXVocGt6cWludGZtIiwicm9sZSI6ImFub24iLCJpYXQiOjE3NDEyODAzMTQsImV4cCI6MjA1Njg1NjMxNH0.4XhPOXyWSKISdyEx7chmU95YDz1ozaXI-Iho7F4mpA4";

export const supabase = createClient<Database>(
  SUPABASE_URL, 
  SUPABASE_PUBLISHABLE_KEY,
  {
    auth: {
      persistSession: true, // Alterado para true para resolver problemas de perda de sessão
      autoRefreshToken: true,
      storageKey: 'supabase.auth.token',
      storage: typeof window !== 'undefined' ? localStorage : undefined,
      detectSessionInUrl: true
    },
    realtime: {
      params: {
        eventsPerSecond: 10
      }
    },
    global: {
      // Adiciona cache control para evitar respostas "stale"
      fetch: (url, options) => {
        return fetch(url, {
          ...options,
          cache: 'no-store',
        });
      }
    }
  }
);

// Variável para rastrear tentativas de reconexão
let reconnectAttempts = 0;
const MAX_RECONNECT_ATTEMPTS = 5;

// Função de verificação de saúde para reconectar caso a conexão realtime seja perdida
const healthCheckInterval = setInterval(() => {
  try {
    // Verifica se há canais ativos
    const channelCount = supabase.getChannels().length;
    
    // Se n��o há canais ativos, não precisamos verificar a conexão
    if (channelCount === 0) {
      reconnectAttempts = 0;
      return;
    }
    
    // Checa o estado da conexão realtime
    let currentState = 'unknown';
    try {
      if (supabase.realtime) {
        // Tenta obter o estado da conexão realtime
        const connectionState = supabase.realtime.connectionState;
        
        if (typeof connectionState === 'function') {
          // Se for uma função (API antiga), chama a função
          currentState = String(connectionState());
        } else if (connectionState !== undefined) {
          // Se for uma propriedade (API nova), acessa diretamente
          currentState = String(connectionState);
        }
        // Removidas as tentativas de acessar transport.readyState diretamente,
        // pois essa propriedade não está disponível no tipo WebSocketLike
      }
    } catch (e) {
      console.warn('Error checking realtime connection state:', e);
    }
    
    // Se houver canais ativos e a conexão estiver desconectada, tenta reconectar
    if (channelCount > 0 && (currentState === "disconnected" || currentState === "closed" || currentState === "3")) {
      reconnectAttempts++;
      
      if (reconnectAttempts <= MAX_RECONNECT_ATTEMPTS) {
        console.log(`Realtime connection lost (${currentState}). Reconnect attempt ${reconnectAttempts}/${MAX_RECONNECT_ATTEMPTS}`);
        
        // Tenta reconectar
        try {
          supabase.realtime.connect();
        } catch (reconnectError) {
          console.warn('Error during realtime reconnection attempt:', reconnectError);
        }
      } else if (reconnectAttempts === MAX_RECONNECT_ATTEMPTS + 1) {
        // Registra apenas uma vez após atingir o limite
        console.warn(`Maximum reconnection attempts (${MAX_RECONNECT_ATTEMPTS}) reached for realtime connection`);
      }
    } else if (currentState === "connected" || currentState === "open" || currentState === "1") {
      // Reseta contagem de tentativas quando a conexão está estável
      reconnectAttempts = 0;
    }
  } catch (error) {
    console.warn('Error in realtime connection health check:', error);
  }
}, 30000); // Verificar a cada 30 segundos

// Limpa o intervalo no unload da página
if (typeof window !== 'undefined') {
  window.addEventListener('beforeunload', () => {
    clearInterval(healthCheckInterval);
  });
}

// Função global para invocar edge functions com retry
export const invokeEdgeFunctionWithRetry = async (
  functionName: string, 
  payload: any, 
  maxRetries = 3
) => {
  let lastError: any = null;
  
  for (let attempt = 0; attempt < maxRetries; attempt++) {
    try {
      const response = await supabase.functions.invoke(functionName, {
        body: payload
      });
      
      if (response.error) {
        throw response.error;
      }
      
      return response;
    } catch (error) {
      console.warn(`Edge function ${functionName} attempt ${attempt + 1}/${maxRetries} failed:`, error);
      lastError = error;
      
      // Backoff exponencial antes de tentar novamente
      if (attempt < maxRetries - 1) {
        await new Promise(r => setTimeout(r, Math.pow(2, attempt) * 1000));
      }
    }
  }
  
  throw lastError || new Error(`Failed to invoke edge function ${functionName} after ${maxRetries} attempts`);
};

// Função utilitária para mascarar dados sensíveis nos logs
export const maskSensitiveData = (data: any): any => {
  if (!data) return data;
  
  // Cria uma cópia profunda para evitar modificar os dados originais
  const dataCopy = JSON.parse(JSON.stringify(data));
  
  // Lista de campos sensíveis a serem mascarados
  const sensitiveFields = [
    'password', 'senha', 'secret', 'token', 'api_key', 'apiKey', 
    'authToken', 'auth_token', 'credentials', 'credit_card', 'creditCard'
  ];
  
  // Função para sanitizar recursivamente um objeto
  const sanitize = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(item => sanitize(item));
    }
    
    if (obj !== null && typeof obj === 'object') {
      Object.keys(obj).forEach(key => {
        const lowerKey = key.toLowerCase();
        
        // Mascarar campos sensíveis
        if (sensitiveFields.some(field => lowerKey.includes(field))) {
          obj[key] = '[REDACTED]';
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          obj[key] = sanitize(obj[key]);
        }
      });
    }
    
    return obj;
  };
  
  return sanitize(dataCopy);
};
