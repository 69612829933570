
// Utility to map between database blog post schema and frontend model
import { supabase } from "@/integrations/supabase/client";

// Database type from Supabase
export interface DatabaseBlogPost {
  id: string;
  title: string;
  slug: string | null;
  excerpt: string | null;
  content: string | null;
  category: string | null;
  featured_image: string | null;
  alt_text: string | null;
  meta_description: string | null;
  tags: string[];
  seo_score: number | null;
  status: string;
  created_at: string;
  updated_at: string;
  published_at: string | null;
  author_id: string;
  views: number;
  author_name?: string;
  tone: string | null;
  image_style: string | null;
  short_tail_keywords: string[] | null;
  long_tail_keywords: string[] | null;
}

// Frontend model
export interface BlogPost {
  id?: string;
  title: string;
  slug?: string;
  excerpt?: string;
  content: string;
  category?: string;
  featuredImage?: string | null;
  altText?: string;
  metaDescription?: string;
  tags?: string[];
  seoScore?: number;
  status: 'draft' | 'published' | 'scheduled';
  createdAt?: string;
  updatedAt?: string;
  publishDate?: string | null;
  author_id?: string;
  authorName?: string;
  views?: number;
  tone?: string;
  imageStyle?: string;
  shortTailKeywords?: string[];
  longTailKeywords?: string[];
  // For compatibility with existing code
  created_at?: string;
  updated_at?: string;
  published_at?: string;
  author_name?: string;
}

// Convert from database model to frontend model
export function dbToFrontendBlogPost(dbPost: DatabaseBlogPost): BlogPost {
  return {
    id: dbPost.id,
    title: dbPost.title,
    slug: dbPost.slug || undefined,
    excerpt: dbPost.excerpt || undefined,
    content: dbPost.content || '',
    category: dbPost.category || undefined,
    featuredImage: dbPost.featured_image,
    altText: dbPost.alt_text || undefined,
    metaDescription: dbPost.meta_description || undefined,
    tags: dbPost.tags || [],
    seoScore: dbPost.seo_score || 0,
    status: (dbPost.status || 'draft') as 'draft' | 'published' | 'scheduled',
    createdAt: dbPost.created_at,
    updatedAt: dbPost.updated_at,
    publishDate: dbPost.published_at || undefined,
    author_id: dbPost.author_id,
    authorName: dbPost.author_name,
    views: dbPost.views || 0,
    tone: dbPost.tone || undefined,
    imageStyle: dbPost.image_style || undefined,
    shortTailKeywords: dbPost.short_tail_keywords || [],
    longTailKeywords: dbPost.long_tail_keywords || [],
    // For compatibility with existing code
    created_at: dbPost.created_at,
    updated_at: dbPost.updated_at,
    published_at: dbPost.published_at || undefined,
    author_name: dbPost.author_name
  };
}

// Convert from frontend model to database model for saving
export function frontendToDbBlogPost(post: BlogPost): Partial<DatabaseBlogPost> {
  // Generate slug if not provided but title exists
  const slug = post.slug || (post.title ? generateSlug(post.title) : null);
  
  return {
    id: post.id,
    title: post.title,
    slug: slug,
    excerpt: post.excerpt || null,
    content: post.content,
    category: post.category || null,
    featured_image: post.featuredImage || null,
    alt_text: post.altText || null,
    meta_description: post.metaDescription || null,
    tags: post.tags || [],
    seo_score: post.seoScore !== undefined ? post.seoScore : null,
    status: post.status,
    published_at: post.publishDate || post.published_at || null,
    author_id: post.author_id,
    tone: post.tone || null,
    image_style: post.imageStyle || null,
    short_tail_keywords: post.shortTailKeywords || null,
    long_tail_keywords: post.longTailKeywords || null
  };
}

// Melhorada: Gera um slug mais amigável para SEO a partir de um título
export function generateSlug(title: string): string {
  return title
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove acentos
    .replace(/[^\w\s-]/g, '') // Remove caracteres especiais
    .replace(/\s+/g, '-') // Substitui espaços por traços
    .replace(/--+/g, '-') // Substitui múltiplos traços por um único
    .trim()
    .replace(/^-+|-+$/g, ''); // Remove traços do início e fim
}

// Fetch author names for a list of blog posts
export async function fetchAuthorNames(posts: any[]): Promise<DatabaseBlogPost[]> {
  // Handle the case where posts might not have all the required properties
  const transformedPosts: DatabaseBlogPost[] = posts.map(post => ({
    id: post.id,
    title: post.title,
    slug: post.slug,
    excerpt: post.excerpt,
    content: post.content,
    category: post.category,
    featured_image: post.featured_image,
    alt_text: post.alt_text,
    meta_description: post.meta_description,
    tags: post.tags || [],
    seo_score: post.seo_score || null,
    status: post.status,
    created_at: post.created_at,
    updated_at: post.updated_at,
    published_at: post.published_at,
    author_id: post.author_id,
    views: post.views || 0,
    author_name: post.author_name,
    tone: post.tone || null,
    image_style: post.image_style || null,
    short_tail_keywords: post.short_tail_keywords || null,
    long_tail_keywords: post.long_tail_keywords || null
  }));
  
  const authorIds = [...new Set(transformedPosts.map(post => post.author_id))].filter(Boolean);
  
  if (authorIds.length === 0) return transformedPosts;
  
  try {
    const { data: profiles } = await supabase
      .from('profiles')
      .select('id, name')
      .in('id', authorIds);
    
    const authorMap = new Map();
    profiles?.forEach(profile => {
      authorMap.set(profile.id, profile.name);
    });
    
    return transformedPosts.map(post => ({
      ...post,
      author_name: post.author_id ? authorMap.get(post.author_id) || 'Usuário' : 'Sistema'
    }));
  } catch (error) {
    console.error("Error fetching author names:", error);
    return transformedPosts;
  }
}
