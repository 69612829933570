
type Theme = 'light' | 'dark';

// Verifica se a rota atual é do dashboard administrativo
export const isDashboardRoute = (): boolean => {
  if (typeof window !== 'undefined') {
    return window.location.pathname.startsWith('/admin');
  }
  return false;
};

// Get the user's theme preference from localStorage or system preference
export const getThemePreference = (): Theme => {
  if (typeof window !== 'undefined') {
    // Check localStorage
    const storedTheme = localStorage.getItem('theme') as Theme | null;
    if (storedTheme) {
      return storedTheme;
    }
    
    // Always default to light theme instead of checking system preference
    // This ensures we never start in dark mode by default
    return 'light';
  }
  
  return 'light'; // Default theme
};

// Set theme and save preference
export const setTheme = (theme: Theme): void => {
  if (typeof window !== 'undefined') {
    const root = window.document.documentElement;
    
    // Verificar se estamos em uma rota do dashboard
    if (isDashboardRoute()) {
      // Remove both classes and add the current one
      root.classList.remove('light', 'dark');
      root.classList.add(theme);
    } else {
      // Para rotas que não são do dashboard, sempre usar o tema light
      root.classList.remove('dark');
      root.classList.add('light');
    }
    
    // Save to localStorage
    localStorage.setItem('theme', theme);
  }
};

// Toggle between light and dark themes
export const toggleTheme = (): Theme => {
  const current = getThemePreference();
  const newTheme = current === 'dark' ? 'light' : 'dark';
  setTheme(newTheme);
  return newTheme;
};

// Initialize theme on app load
export const initializeTheme = (): (() => void) | void => {
  if (typeof window !== 'undefined') {
    // Aplicar tema baseado na rota atual
    if (isDashboardRoute()) {
      setTheme(getThemePreference());
    } else {
      // Fora do dashboard, sempre usa tema light
      setTheme('light');
    }
    
    // Add listener for route changes
    const handleRouteChange = () => {
      if (isDashboardRoute()) {
        setTheme(getThemePreference());
      } else {
        setTheme('light');
      }
    };
    
    // Listen for route changes
    window.addEventListener('popstate', handleRouteChange);
    
    // Clean up listeners when needed
    return () => {
      window.removeEventListener('popstate', handleRouteChange);
    };
  }
};
