
import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { initErrorTracking } from './utils/errorTracker.ts'
import { router } from './routes.tsx'
import { RouterProvider } from 'react-router-dom'
import { Toaster } from "@/components/ui/toaster"
import { QueryClientProvider } from "@tanstack/react-query"
import { queryClient } from "./lib/react-query"
import { ThemeProvider } from "next-themes"
import { AlertProvider } from "@/components/ui/alert-provider"
import { initializeTheme } from './utils/themeUtils'
import { initActivityListeners } from './utils/sessionUtils'
import { Loading } from './components/ui/loading'
import { trackPageView } from './utils/trackingUtils'
import { initConsoleProtection } from './utils/consoleSuppressionUtils'

// Initialize console protection
initConsoleProtection();

// Initialize error tracking for system monitoring
initErrorTracking();

// Initialize theme settings
initializeTheme();

// Initialize session activity tracking
initActivityListeners();

// Application loading state
let appLoading = true;
const loadingTimeout = setTimeout(() => {
  // If the app is still loading after 10 seconds, show an error
  const loadingEl = document.getElementById('app-loading-indicator');
  if (loadingEl) {
    const errorEl = document.createElement('div');
    errorEl.className = 'text-center mt-4 text-sm text-amber-600';
    errorEl.innerHTML = 'Está demorando mais do que o esperado. <button class="underline text-primary" onclick="window.location.reload()">Tentar recarregar</button>';
    loadingEl.appendChild(errorEl);
  }
}, 10000);

// Improved function to check if JivoChat script should be loaded
const shouldLoadJivoChat = () => {
  const path = window.location.pathname;
  // Don't load JivoChat on admin routes or login page
  return !path.includes('/admin') && !path.includes('/login');
};

// Function to manage JivoChat script
const manageJivoChat = () => {
  const jivoId = "DBHa3CRL25"; // Your JivoChat widget ID

  // Check if we need to show JivoChat
  if (shouldLoadJivoChat()) {
    console.log("JivoChat initialized for public pages");
    
    // Load the JivoChat script
    const loadJivo = () => {
      // Check if JivoChat script exists
      if (!document.getElementById('jivo-script')) {
        const script = document.createElement('script');
        script.id = 'jivo-script';
        script.src = `//code.jivosite.com/widget/${jivoId}`;
        script.async = true;
        document.body.appendChild(script);
        console.log("JivoChat script added to DOM");
      }
    };
    
    // Small delay to ensure DOM is ready
    setTimeout(loadJivo, 1000);
  } else {
    console.log("JivoChat disabled for admin/login pages");
    
    // Remove JivoChat script if it exists
    const jivoScript = document.getElementById('jivo-script');
    if (jivoScript) {
      jivoScript.remove();
      console.log("JivoChat script removed from DOM");
    }
    
    // Remove any existing JivoChat widget container
    const jivoContainer = document.getElementById('jivo-iframe-container');
    if (jivoContainer) {
      jivoContainer.remove();
      console.log("JivoChat widget removed from admin/login page");
    }
    
    // Attempt to remove JivoChat from window
    try {
      // Use optional chaining to safely access window properties
      if (window.jivo_api) {
        window.jivo_api.close();
        console.log("JivoChat API closed");
      }
      if (window.jivo_init) {
        window.jivo_init = undefined;
        console.log("JivoChat init removed");
      }
    } catch (e) {
      console.error("Error cleaning up JivoChat:", e);
    }
  }
};

// Initial management of JivoChat
manageJivoChat();

// Track page views on navigation
const trackRouteChange = () => {
  const pathname = window.location.pathname;
  const title = document.title || 'Preço Medicamentos';
  
  // Don't track admin pages
  if (!pathname.includes('/admin')) {
    trackPageView(pathname, title);
  }
};

// Setup navigation handler to toggle JivoChat on route change and track page views
const originalPushState = history.pushState;
history.pushState = function(...args) {
  originalPushState.apply(this, args);
  manageJivoChat();
  trackRouteChange();
};

window.addEventListener('popstate', () => {
  manageJivoChat();
  trackRouteChange();
});

// Create React root and mount application
const rootElement = document.getElementById('root');

// Add initial loading indicator
const initialLoading = document.createElement('div');
initialLoading.id = 'app-loading-indicator';
initialLoading.className = 'fixed inset-0 flex flex-col items-center justify-center bg-background z-[9999]';
initialLoading.innerHTML = `
  <div class="h-12 w-12 animate-spin rounded-full border-b-2 border-t-2 border-primary"></div>
  <p class="mt-4 text-sm text-muted-foreground">Carregando aplicativo...</p>
`;
document.body.appendChild(initialLoading);

if (!rootElement) {
  console.error('Root element not found! Application cannot start.');
  
  // Show error instead of loading indicator
  if (initialLoading) {
    initialLoading.innerHTML = `
      <div class="text-red-500 text-xl mb-4">Erro: elemento root não encontrado</div>
      <button onclick="window.location.reload()" class="px-4 py-2 bg-primary text-primary-foreground rounded-md">
        Recarregar Página
      </button>
    `;
  }
} else {
  try {
    ReactDOM.createRoot(rootElement).render(
      <React.StrictMode>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider attribute="class" defaultTheme="light" enableSystem={false}>
            <AlertProvider>
              <Toaster />
              <RouterProvider router={router} fallbackElement={<Loading fullScreen text="Carregando rota..." />} />
            </AlertProvider>
          </ThemeProvider>
        </QueryClientProvider>
      </React.StrictMode>
    );
    
    // Track initial page view after small delay
    setTimeout(() => {
      trackRouteChange();
    }, 500);
    
    // Remove the loading indicator once React has mounted
    const removeLoading = () => {
      appLoading = false;
      clearTimeout(loadingTimeout);
      const loadingIndicator = document.getElementById('app-loading-indicator');
      if (loadingIndicator) {
        loadingIndicator.remove();
      }
    };
    
    // Give React some time to render before removing the loading indicator
    setTimeout(removeLoading, 500);
  } catch (error) {
    console.error('Failed to render application:', error);
    
    // Update the loading indicator to show the error
    if (initialLoading) {
      initialLoading.innerHTML = `
        <div class="text-red-500 text-xl mb-4">Erro ao iniciar aplicação</div>
        <p class="text-sm text-gray-600 mb-4">Detalhes: ${error.message || 'Erro desconhecido'}</p>
        <button onclick="window.location.reload()" class="px-4 py-2 bg-primary text-primary-foreground rounded-md">
          Recarregar Página
        </button>
      `;
    }
    
    // Fallback rendering with minimal dependencies
    ReactDOM.createRoot(rootElement).render(
      <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
        <h1>Ocorreu um erro ao iniciar a aplicação</h1>
        <p>Tente recarregar a página ou entre em contato com o suporte se o problema persistir.</p>
        <p style={{ color: 'red', marginTop: '10px' }}>Erro: {error.message || 'Erro desconhecido'}</p>
        <button 
          onClick={() => window.location.reload()} 
          style={{ 
            padding: '8px 16px', 
            background: '#2563eb', 
            color: 'white', 
            border: 'none', 
            borderRadius: '4px', 
            marginTop: '16px',
            cursor: 'pointer' 
          }}
        >
          Recarregar Página
        </button>
      </div>
    );
  }
}
