
import { QueryClient } from '@tanstack/react-query';

// Cria uma instância do QueryClient com configurações padrão
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // 5 minutos
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});
