
import { supabase } from "@/integrations/supabase/client";
import { fetchUserProfile, UserProfile } from "./profileUtils";
import { translateError, extractErrorMessage } from "./errorTranslator";
import { clearAuthData, safeSetItem, recoverSession } from "./sessionUtils";

export interface AuthUser {
  id: string;
  email?: string;
  [key: string]: any;
}

export interface LoginResult {
  user: AuthUser | null;
  profile: UserProfile | null;
  error?: string;
}

export interface ResetPasswordResult {
  success: boolean;
  error?: string;
}

// Cache to prevent duplicate getCurrentUser calls in the same render cycle
let userCache: {
  user: AuthUser | null;
  timestamp: number;
} | null = null;
const CACHE_TTL = 3000; // 3 seconds

export const getCurrentUser = async () => {
  try {
    // Check cache first to prevent excessive calls
    if (userCache && (Date.now() - userCache.timestamp < CACHE_TTL)) {
      return userCache.user;
    }
    
    console.log("getCurrentUser: Verificando sessão atual");
    const { data, error } = await supabase.auth.getSession();
    
    if (error) {
      console.error("getCurrentUser: Erro ao verificar sessão:", error.message);
      clearAuthData(); // Clean up potentially corrupted session data
      userCache = { user: null, timestamp: Date.now() };
      return null;
    }
    
    if (!data?.session?.user) {
      console.log("getCurrentUser: Nenhuma sessão encontrada");
      
      // Try to recover the session
      const recovered = await recoverSession();
      if (recovered) {
        // If session recovered, fetch it again
        const { data: refreshedData } = await supabase.auth.getSession();
        if (refreshedData?.session?.user) {
          console.log("getCurrentUser: Sessão recuperada com sucesso");
        } else {
          // Clean up if recovery seemed to work but no session exists
          clearAuthData();
          userCache = { user: null, timestamp: Date.now() };
          return null;
        }
      } else {
        // No recovery possible, clean up
        clearAuthData();
        userCache = { user: null, timestamp: Date.now() };
        return null;
      }
    }
    
    const userId = data.session.user.id;
    const userEmail = data.session.user.email;
    console.log("getCurrentUser: Sessão encontrada para usuário:", userId);

    // Get user profile data
    const profileData = await fetchUserProfile(userId);
    
    if (!profileData) {
      console.warn("getCurrentUser: Nenhum perfil encontrado para o usuário:", userId);
    }
    
    // Store authentication status for faster subsequent checks
    try {
      sessionStorage.setItem('isAuthenticated', 'true');
    } catch (e) {
      console.warn("Não foi possível armazenar status de autenticação:", e);
    }
    
    // Store the role in localStorage for faster subsequent loads
    if (profileData?.role) {
      safeSetItem(localStorage, 'userRole', profileData.role);
    }
    
    // Cache user data to prevent excess calls
    const userData = {
      ...data.session.user,
      role: profileData?.role || null,
      name: profileData?.name || null
    };
    
    userCache = {
      user: userData,
      timestamp: Date.now()
    };
    
    return userData;
  } catch (error) {
    console.error("Erro em getCurrentUser:", error);
    clearAuthData(); // Clean up on error
    userCache = { user: null, timestamp: Date.now() };
    return null;
  }
};

export const loginUser = async (email: string, password: string): Promise<LoginResult> => {
  console.log("loginUser: Tentando login com:", email);
  
  if (!email || !password) {
    return { 
      user: null, 
      profile: null, 
      error: "Email e senha são obrigatórios" 
    };
  }
  
  try {
    // Clear user cache
    userCache = null;
    
    // Verificar quaisquer sessões anteriores e limpar
    console.log("loginUser: Verificando sessões anteriores");
    const previousSession = await supabase.auth.getSession();
    if (previousSession.data.session) {
      console.log("loginUser: Sessão anterior encontrada, realizando logout");
      await supabase.auth.signOut({ scope: 'global' });
      
      // Breve pausa para garantir que o logout foi processado
      await new Promise(resolve => setTimeout(resolve, 300));
    }
    
    // Limpar completamente quaisquer dados de autenticação anteriores
    console.log("loginUser: Limpando dados de sessão anteriores");
    clearAuthData();
    
    // Adicionar um pequeno atraso para garantir que os dados anteriores sejam limpos
    await new Promise(resolve => setTimeout(resolve, 300));
    
    console.log("loginUser: Iniciando nova tentativa de login com email:", email);
    
    // Tentar login com as credenciais fornecidas - usando diretamente com email e senha limpos
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email.trim().toLowerCase(),
      password: password
    });
    
    if (error) {
      console.error("loginUser: Erro de autenticação:", error);
      return {
        user: null,
        profile: null,
        error: translateError(error.message) || "Credenciais inválidas ou erro no servidor"
      };
    }
    
    if (!data.user) {
      console.error("loginUser: Login bem-sucedido mas sem dados do usuário");
      return {
        user: null,
        profile: null,
        error: "Falha no login. Usuário não encontrado."
      };
    }
    
    console.log("loginUser: Usuário autenticado com sucesso:", data.user.id);
    
    // Fetch profile data after successful login
    console.log("loginUser: Buscando dados do perfil");
    const profileData = await fetchUserProfile(data.user.id);
    console.log("loginUser: Dados do perfil obtidos:", profileData ? "sim" : "não");
    
    // Store authentication status
    try {
      sessionStorage.setItem('isAuthenticated', 'true');
    } catch (e) {
      console.warn("Não foi possível armazenar status de autenticação:", e);
    }
    
    // Store the role for faster subsequent loads
    if (profileData?.role) {
      safeSetItem(localStorage, 'userRole', profileData.role);
    }
    
    // Verify session to confirm login was successful
    const sessionCheck = await supabase.auth.getSession();
    console.log("Verificação de sessão após login:", 
      sessionCheck.data.session ? "Sessão existe" : "Nenhuma sessão encontrada");
    
    // Reset user cache
    userCache = null;
    
    // Return user and profile data
    return { 
      user: {
        ...data.user,
        role: profileData?.role || null
      }, 
      profile: profileData,
      error: undefined
    };
  } catch (error: any) {
    console.error("loginUser: Erro na tentativa de login:", error);
    return {
      user: null,
      profile: null,
      error: extractErrorMessage(error) || "Erro inesperado durante o login"
    };
  }
};

export const logoutUser = async (redirect = true) => {
  try {
    // Reset user cache
    userCache = null;
    
    console.log("logoutUser: Iniciando processo de logout");
    
    // Limpar dados de autenticação do armazenamento local
    clearAuthData();
    
    const { error } = await supabase.auth.signOut({
      scope: 'global' // Ensure full logout, not just local
    });
    
    if (error) {
      console.error("logoutUser: Erro ao fazer logout:", error.message);
      throw error;
    }
    
    console.log("logoutUser: Logout realizado com sucesso");
    
    // Aguardar um pouco para garantir que a sessão foi completamente limpa
    await new Promise(resolve => setTimeout(resolve, 300));
    
    // Forçar redirecionamento para a página inicial após logout somente se redirect=true
    if (redirect) {
      window.location.href = "/login";
    }
    
    return true;
  } catch (error) {
    console.error("logoutUser: Erro no processo de logout:", error);
    throw error;
  }
};

export const resetPassword = async (email: string): Promise<ResetPasswordResult> => {
  if (!email) {
    return {
      success: false,
      error: "Email é obrigatório"
    };
  }
  
  try {
    console.log("resetPassword: Enviando email de recuperação para:");
    
    // Configurar a URL de redirecionamento para a página de redefinição de senha
    // Usando URL absoluta para evitar problemas com caminhos relativos
    const origin = window.location.origin;
    const redirectUrl = `${origin}/reset-password`;
    
    console.log("resetPassword: URL de redirecionamento:", redirectUrl);
    
    const { error } = await supabase.auth.resetPasswordForEmail(email.trim().toLowerCase(), {
      redirectTo: redirectUrl
    });
    
    if (error) {
      console.error("resetPassword: Erro ao enviar email:", error.message);
      return {
        success: false,
        error: translateError(error.message) || "Erro ao enviar email de recuperação"
      };
    }
    
    console.log("resetPassword: Email de recuperação enviado com sucesso");
    return {
      success: true
    };
  } catch (error: any) {
    console.error("resetPassword: Erro inesperado:", error);
    return {
      success: false,
      error: extractErrorMessage(error) || "Erro inesperado ao processar a recuperação de senha"
    };
  }
};

// Maximum number of auth listeners to prevent memory leaks
let activeAuthListeners = 0;
const MAX_AUTH_LISTENERS = 3;

export const setupAuthObserver = (
  onSignIn: (user: AuthUser) => void,
  onSignOut: () => void
) => {
  // Prevent too many listeners
  if (activeAuthListeners >= MAX_AUTH_LISTENERS) {
    console.warn(`Excesso de observadores de autenticação (${activeAuthListeners}). Descartando solicitação.`);
    return { unsubscribe: () => {} };
  }
  
  console.log("Configurando observador de autenticação");
  activeAuthListeners++;
  
  const { data } = supabase.auth.onAuthStateChange((event, session) => {
    console.log("Estado de autenticação alterado:", event);
    
    if (event === 'SIGNED_IN' && session?.user) {
      console.log("Evento SIGNED_IN detectado com sessão válida");
      
      // Para evitar deadlocks, usamos setTimeout para operações assíncronas
      setTimeout(async () => {
        try {
          // Obter os dados do perfil quando o usuário estiver conectado
          const profileData = await fetchUserProfile(session.user.id);
          
          // Armazenar o status de autenticação
          try {
            sessionStorage.setItem('isAuthenticated', 'true');
          } catch (e) {
            console.warn("Não foi possível armazenar status de autenticação:", e);
          }
          
          // Armazenar a função para carregamentos subsequentes mais rápidos
          if (profileData?.role) {
            safeSetItem(localStorage, 'userRole', profileData.role);
          }
          
          // Reset user cache on new sign in
          userCache = null;
          
          onSignIn({
            ...session.user,
            role: profileData?.role || null,
            name: profileData?.name || null
          });
        } catch (error) {
          console.error("Erro ao processar evento de login:", error);
        }
      }, 0);
    } else if (event === 'SIGNED_OUT') {
      console.log("Evento SIGNED_OUT detectado");
      
      // Reset user cache
      userCache = null;
      
      // Limpar dados de autenticação do armazenamento local
      clearAuthData();
      
      onSignOut();
    } else if (event === 'TOKEN_REFRESHED') {
      console.log("Evento TOKEN_REFRESHED detectado, atualizando cache de usuário");
      // Reset user cache to force a refetch
      userCache = null;
    }
  });
  
  // Ensure unsubscribe decrements the counter
  const originalUnsubscribe = data.subscription.unsubscribe;
  data.subscription.unsubscribe = () => {
    activeAuthListeners = Math.max(0, activeAuthListeners - 1);
    originalUnsubscribe.call(data.subscription);
  };
  
  return data.subscription;
};

// For debugging
export const getAuthDebugInfo = async () => {
  if (process.env.NODE_ENV !== 'development') {
    return "Debug info only available in development";
  }
  
  try {
    const sessionData = await supabase.auth.getSession();
    const currentUser = userCache ? userCache.user : null;
    
    return {
      hasSession: !!sessionData.data.session,
      sessionExpires: sessionData.data.session 
        ? new Date(sessionData.data.session.expires_at * 1000).toISOString() 
        : null,
      userCache: {
        exists: !!userCache,
        timestamp: userCache ? new Date(userCache.timestamp).toISOString() : null,
        age: userCache ? Date.now() - userCache.timestamp : null
      },
      localStorage: {
        hasToken: !!localStorage.getItem('supabase.auth.token'),
        hasRefreshToken: !!localStorage.getItem('sb-refresh-token'),
        role: localStorage.getItem('userRole')
      },
      sessionStorage: {
        isAuthenticated: sessionStorage.getItem('isAuthenticated')
      }
    };
  } catch (error) {
    return { error: "Error getting debug info" };
  }
};
