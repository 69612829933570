
/**
 * Utility functions for tracking user interactions with Google Analytics
 */

import { UtmParams } from "@/hooks/useUtmParams";

interface TrackEventOptions {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  utmParams?: UtmParams | Record<string, string>;
}

/**
 * Track an event with Google Analytics
 */
export const trackEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction = false,
  utmParams = {}
}: TrackEventOptions): void => {
  try {
    // Check if gtag is available
    if (typeof window !== 'undefined' && window.gtag) {
      console.log(`Tracking event: ${category} - ${action} - ${label}`);
      
      // Build the event parameters
      const eventParams: Record<string, any> = {
        event_category: category,
        event_label: label,
        value: value,
        non_interaction: nonInteraction,
      };
      
      // Add UTM parameters if available
      Object.entries(utmParams).forEach(([key, value]) => {
        if (value) {
          eventParams[key] = value;
        }
      });
      
      // Send the event to Google Analytics
      window.gtag('event', action, eventParams);
      
      // Also push to dataLayer for GTM
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'custom_event',
          event_category: category,
          event_action: action,
          event_label: label,
          event_value: value,
          ...utmParams
        });
      }
    }
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

/**
 * Track a page view with Google Analytics
 */
export const trackPageView = (path: string, title: string): void => {
  try {
    if (typeof window !== 'undefined' && window.gtag) {
      console.log(`Tracking page view: ${path} - ${title}`);
      
      window.gtag('config', 'G-8BSBGSB7XM', {
        page_path: path,
        page_title: title
      });
      
      // Also push to dataLayer for GTM
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'page_view',
          page_path: path,
          page_title: title
        });
      }
    }
  } catch (error) {
    console.error('Error tracking page view:', error);
  }
};

/**
 * Track common button clicks - reusable function for components
 */
export const trackButtonClick = (
  buttonName: string, 
  buttonType: 'whatsapp' | 'phone' | 'email' | 'navigation' | 'action' | 'external',
  destination?: string,
  utmParams?: UtmParams | Record<string, string>
): void => {
  trackEvent({
    category: 'Button',
    action: 'Click',
    label: `${buttonName} - ${buttonType}${destination ? ` - ${destination}` : ''}`,
    utmParams
  });
};

/**
 * Track form submissions
 */
export const trackFormSubmission = (formName: string, formData?: any): void => {
  trackEvent({
    category: 'Form',
    action: 'Submit',
    label: formName
  });
};

/**
 * Track search actions
 */
export const trackSearch = (searchTerm: string, resultsCount?: number): void => {
  trackEvent({
    category: 'Search',
    action: 'Query',
    label: searchTerm,
    value: resultsCount
  });
};
