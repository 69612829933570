
import { useState, useEffect } from "react";

interface RotatingWordsProps {
  words: string[];
  currentIndex: number;
  animationsEnabled: boolean;
}

export const RotatingWords = ({ words, currentIndex, animationsEnabled }: RotatingWordsProps) => {
  const rotatingWordClasses = (index: number) => 
    `absolute w-full transition-transform ${animationsEnabled ? 'duration-500 ease-in-out' : ''} ${index === currentIndex ? "translate-y-0 opacity-100" : "translate-y-20 opacity-0"}`;

  return (
    <div className="h-24 overflow-hidden relative mt-2">
      {words.map((word, index) => (
        <div key={index} className={rotatingWordClasses(index)}>
          <span className="text-5xl md:text-6xl lg:text-7xl text-primary font-bold bg-gradient-to-r from-primary via-primary-dark to-primary bg-clip-text text-transparent">
            {word}
          </span>
        </div>
      ))}
    </div>
  );
};
