
import { useState } from "react";
import { useToast } from "@/hooks/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useUtmParams } from "@/hooks/useUtmParams";

interface FormData {
  name: string;
  email: string;
  phone: string;
  medicine: string;
  details: string;
}

export const useJudicialForm = () => {
  const [formData, setFormData] = useState<FormData>({
    name: "",
    email: "",
    phone: "",
    medicine: "",
    details: ""
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { toast } = useToast();
  const utmParams = useUtmParams();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    
    // Clear error messages when user starts typing
    if (submitError) {
      setSubmitError(null);
    }
  };

  const validateForm = () => {
    if (!formData.name) return "Nome é obrigatório";
    if (!formData.email) return "Email é obrigatório";
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) return "Email inválido";
    if (!formData.phone) return "Telefone é obrigatório";
    if (!formData.medicine) return "Medicamento é obrigatório";
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitError(null);
    
    const validationError = validateForm();
    if (validationError) {
      toast({
        title: "Erro de validação",
        description: validationError,
        variant: "destructive"
      });
      setSubmitError(validationError);
      return;
    }
    
    try {
      setIsSubmitting(true);
      console.log("Enviando solicitação para a função send-judicial-demand");

      const submissionData = {
        ...formData,
        date: new Date().toLocaleDateString('pt-BR'),
        time: new Date().toLocaleTimeString('pt-BR'),
        url: window.location.href,
        ...utmParams
      };
      
      console.log("Dados a serem enviados:", submissionData);
      
      const { data, error } = await supabase.functions.invoke('send-judicial-demand', {
        body: submissionData
      });
      
      if (error) {
        console.error("Erro na invocação da função:", error);
        throw new Error(error.message || "Erro ao enviar solicitação");
      }
      
      console.log("Resposta da API:", data);

      // Check for partial errors (some emails sent, others not)
      if (data && data.warnings && data.warnings.length > 0) {
        console.warn("Avisos de envio parcial:", data.warnings);
        
        // Show warning but still consider as partial success
        toast({
          title: "Envio parcial",
          description: "Sua solicitação foi processada, mas pode haver atrasos na comunicação. Entraremos em contato em breve.",
          variant: "warning"
        });
      } else {
        // Show success alert
        setShowSuccessAlert(true);

        toast({
          title: "Solicitação enviada",
          description: "Sua solicitação de cotação foi enviada com sucesso! Entraremos em contato em breve.",
          variant: "success"
        });
      }

      // Reset form after success
      setTimeout(() => {
        setFormData({
          name: "",
          email: "",
          phone: "",
          medicine: "",
          details: ""
        });

        // Hide success alert after 5 seconds
        setTimeout(() => {
          setShowSuccessAlert(false);
        }, 5000);
      }, 2000);
      
    } catch (error: any) {
      console.error("Erro ao enviar formulário:", error);
      
      const errorMessage = error?.message || "Ocorreu um erro ao enviar sua solicitação. Por favor, tente novamente.";
      
      setSubmitError(errorMessage);
      
      toast({
        title: "Erro ao enviar",
        description: errorMessage,
        variant: "destructive"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    formData,
    isSubmitting,
    showSuccessAlert,
    submitError,
    handleChange,
    handleSubmit
  };
};
