
import { useState, useEffect, useCallback, useRef } from 'react';
import { searchMedicationsAutocomplete } from '@/utils/supabaseUtils';

export type MedicationSuggestion = {
  id: string;
  name: string;
  manufacturer: string | null;
  price: number | null;
  slug: string | null;
  indications?: string | null;
  active_ingredient?: string | null; // Adicionado campo de princípio ativo
};

type CacheItem = {
  timestamp: number;
  data: MedicationSuggestion[];
};

export function useAutocompleteSearch() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [suggestions, setSuggestions] = useState<MedicationSuggestion[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  
  // Cache para armazenar resultados recentes
  const cacheRef = useRef<Map<string, CacheItem>>(new Map());
  const CACHE_DURATION = 60000; // 1 minuto
  
  const fetchSuggestions = useCallback(async (term: string) => {
    if (term.length < 2) {
      setSuggestions([]);
      return;
    }
    
    // Verificar cache
    const cached = cacheRef.current.get(term);
    if (cached && Date.now() - cached.timestamp < CACHE_DURATION) {
      console.log('Usando resultado em cache para:', term);
      setSuggestions(cached.data);
      return;
    }
    
    setIsLoading(true);
    setError(null);
    
    try {
      console.log('Buscando sugestões para:', term);
      const data = await searchMedicationsAutocomplete(term);
      
      // Armazenar no cache
      cacheRef.current.set(term, {
        timestamp: Date.now(),
        data: data as MedicationSuggestion[]
      });
      
      setSuggestions(data as MedicationSuggestion[]);
    } catch (err) {
      console.error('Erro na busca de autocomplete:', err);
      setError('Ocorreu um erro ao comunicar com o servidor.');
      setSuggestions([]);
    } finally {
      setIsLoading(false);
    }
  }, []);
  
  useEffect(() => {
    if (!searchTerm) {
      setSuggestions([]);
      return;
    }
    
    // Reduzido o tempo de debounce para 150ms
    const timeoutId = setTimeout(() => {
      if (searchTerm && searchTerm.length >= 2) {
        fetchSuggestions(searchTerm);
      }
    }, 150);
    
    return () => clearTimeout(timeoutId);
  }, [searchTerm, fetchSuggestions]);
  
  const clearSuggestions = useCallback(() => {
    setSuggestions([]);
    setError(null);
  }, []);
  
  return {
    searchTerm,
    setSearchTerm,
    suggestions,
    isLoading,
    error,
    clearSuggestions,
    fetchSuggestions
  };
}
