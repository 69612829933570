import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { AuthGuard } from "./utils/authGuard";
import { ErrorBoundary } from "./components/admin/common/ErrorBoundary";
import { Loading } from "./components/ui/loading";

// Better loading component for admin routes
const AdminLoading = () => (
  <div className="flex h-screen w-full items-center justify-center bg-background">
    <div className="flex flex-col items-center">
      <div className="h-12 w-12 animate-spin rounded-full border-b-2 border-t-2 border-primary"></div>
      <p className="mt-4 text-sm text-muted-foreground">Carregando área administrativa...</p>
    </div>
  </div>
);

// Empty component for public routes - just show a quick loading indicator
const PublicLoading = () => (
  <div className="flex h-screen w-full items-center justify-center">
    <div className="h-8 w-8 animate-spin rounded-full border-b-2 border-t-2 border-primary"></div>
  </div>
);

// Only import critical routes immediately, everything else is lazy loaded
import Index from "./pages/Index";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";

// Lazy load remaining public routes
const Medicines = React.lazy(() => import("./pages/Medicines"));
const MedicineDetail = React.lazy(() => import("./pages/MedicineDetail"));
const ComoFunciona = React.lazy(() => import("./pages/ComoFunciona"));
const Blog = React.lazy(() => import("./pages/Blog"));
const BlogDetail = React.lazy(() => import("./pages/BlogDetail"));
const PoliticaPrivacidade = React.lazy(() => import("./pages/PoliticaPrivacidade"));
const PoliticaCompliance = React.lazy(() => import("./pages/PoliticaCompliance"));
const QuemSomos = React.lazy(() => import("./pages/QuemSomos"));

// Import admin pages
const AdminConfiguracoes = React.lazy(() => import("./pages/admin/Configuracoes"));
const Dashboard = React.lazy(() => import("./pages/admin/Dashboard"));
const ChatSupport = React.lazy(() => import("./pages/admin/ChatSupport"));

// Admin medicamentos - grouped lazy loading
const AdminMedicamentosIndex = React.lazy(() => import("./pages/admin/medicamentos/index"));
const AdminMedicamentosForm = React.lazy(() => import("./pages/admin/medicamentos/MedicationForm"));
const AdminMedicamentosCategorias = React.lazy(() => import("./pages/admin/medicamentos/Categorias"));
const AdminMedicamentosCategoriasIndex = React.lazy(() => import("./pages/admin/medicamentos/categorias/index"));
const AdminMedicamentosPrincipiosAtivos = React.lazy(() => import("./pages/admin/medicamentos/PrincipiosAtivos"));
const AdminMedicamentosRelacionamentos = React.lazy(() => import("./pages/admin/medicamentos/Relacionamentos"));

// Admin marketing - grouped lazy loading
const AdminMarketing = {
  Blog: React.lazy(() => import("@/pages/admin/marketing/Blog")),
  BlogPost: React.lazy(() => import("@/pages/admin/marketing/BlogPost")),
  BlogEditor: React.lazy(() => import("@/pages/admin/BlogEditor")),
  GoogleAds: React.lazy(() => import("@/pages/admin/marketing/GoogleAds")),
  Facebook: React.lazy(() => import("@/pages/admin/marketing/Facebook")),
  Instagram: React.lazy(() => import("@/pages/admin/marketing/Instagram")),
  LinkedIn: React.lazy(() => import("@/pages/admin/marketing/LinkedIn")),
  Email: React.lazy(() => import("@/pages/admin/marketing/Email")),
  WhatsApp: React.lazy(() => import("@/pages/admin/marketing/WhatsApp")),
  YouTube: React.lazy(() => import("@/pages/admin/marketing/YouTube")),
  BlogGenerator: React.lazy(() => import("@/pages/admin/marketing/BlogGenerator")),
};

// Admin propostas - grouped lazy loading
const AdminPropostas = {
  Lista: React.lazy(() => import("./pages/admin/propostas/Lista")),
  Nova: React.lazy(() => import("./pages/admin/propostas/NovaProposta")),
  Editar: React.lazy(() => import("./pages/admin/propostas/EditarProposta")),
  Visualizar: React.lazy(() => import("./pages/admin/propostas/VisualizarProposta")),
  Revisao: React.lazy(() => import("./pages/admin/propostas/RevisaoCuracao")),
  Ofertas: React.lazy(() => import("./pages/admin/propostas/Ofertas")),
  Condicoes: React.lazy(() => import("./pages/admin/propostas/Condicoes")),
  RevisaoPedidos: React.lazy(() => import("./pages/admin/propostas/RevisaoPedidos")),
  TiposLeads: React.lazy(() => import("./pages/admin/propostas/TiposLeads")),
  Revalidacao: React.lazy(() => import("./pages/admin/propostas/Revalidacao")),
};

// Admin clientes - grouped lazy loading  
const AdminClientes = {
  Index: React.lazy(() => import("@/pages/admin/Clientes")),
  Adicionar: React.lazy(() => import("@/pages/admin/clientes/adicionar")),
  Visualizar: React.lazy(() => import("@/pages/admin/clientes/visualizar/[id]")),
  Editar: React.lazy(() => import("@/pages/admin/clientes/editar/[id]")),
};

// Admin parceiros - grouped lazy loading  
const AdminParceiros = {
  Index: React.lazy(() => import("@/pages/admin/Parceiros")),
  Adicionar: React.lazy(() => import("@/pages/admin/parceiros/adicionar")),
  Visualizar: React.lazy(() => import("@/pages/admin/parceiros/visualizar/[id]")),
  Editar: React.lazy(() => import("@/pages/admin/parceiros/editar/[id]")),
  Xml: React.lazy(() => import("@/pages/admin/parceiros/xml")),
  XmlAdd: React.lazy(() => import("@/pages/admin/parceiros/xml/adicionar")),
  XmlEdit: React.lazy(() => import("@/pages/admin/parceiros/xml/editar/[id]")),
  Relatorios: React.lazy(() => import("@/pages/admin/parceiros/relatorios")),
  Usuarios: React.lazy(() => import("@/pages/admin/parceiros/usuarios")),
};

// Admin relatórios - grouped lazy loading
const AdminRelatorios = {
  Alertas: React.lazy(() => import("@/pages/admin/relatorios/AlertReports")),
  Conversoes: React.lazy(() => import("@/pages/admin/relatorios/ConversionsReports")),
  Marketing: React.lazy(() => import("@/pages/admin/relatorios/MarketingReports")),
  Parceiros: React.lazy(() => import("@/pages/admin/relatorios/PartnerReports")),
  Precos: React.lazy(() => import("@/pages/admin/relatorios/PriceReports")),
  Buscas: React.lazy(() => import("@/pages/admin/relatorios/SearchReports")),
};

// Admin newsletter - import apenas quando necessário
const AdminNewsletter = React.lazy(() => import("@/pages/admin/Newsletter"));

// Wrapper for lazy loaded public routes with minimal loading indicator
const PublicComponent = ({ component: Component, ...props }) => (
  <React.Suspense fallback={<PublicLoading />}>
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  </React.Suspense>
);

// Wrapper for lazy loaded admin routes with better loading indicator
const AdminComponent = ({ component: Component, ...props }) => (
  <React.Suspense fallback={<AdminLoading />}>
    <ErrorBoundary>
      <Component {...props} />
    </ErrorBoundary>
  </React.Suspense>
);

// Create the router with all routes
export const router = createBrowserRouter([
  // Public routes - critical paths loaded immediately
  {
    path: "/",
    element: <ErrorBoundary><Index /></ErrorBoundary>
  },
  {
    path: "/login",
    element: <ErrorBoundary><Login /></ErrorBoundary>
  },
  
  // Public routes - lazy loaded
  {
    path: "/medicamentos",
    element: <PublicComponent component={Medicines} />
  },
  {
    path: "/medicamentos/:slug",
    element: <PublicComponent component={MedicineDetail} />
  },
  {
    path: "/como-funciona",
    element: <PublicComponent component={ComoFunciona} />
  },
  {
    path: "/blog",
    element: <PublicComponent component={Blog} />
  },
  {
    path: "/blog/:slug",
    element: <PublicComponent component={BlogDetail} />
  },
  {
    path: "/politica-privacidade",
    element: <PublicComponent component={PoliticaPrivacidade} />
  },
  {
    path: "/politica-compliance",
    element: <PublicComponent component={PoliticaCompliance} />
  },
  {
    path: "/quem-somos",
    element: <PublicComponent component={QuemSomos} />
  },
  
  // Admin routes - all protected by AuthGuard, all with loading indicator
  {
    path: "/admin",
    element: <AuthGuard><AdminComponent component={Dashboard} /></AuthGuard>
  },
  {
    path: "/admin/dashboard",
    element: <AuthGuard><AdminComponent component={Dashboard} /></AuthGuard>
  },
  {
    path: "/admin/chat",
    element: <AuthGuard><AdminComponent component={ChatSupport} /></AuthGuard>
  },
  
  // Admin medicamentos routes
  {
    path: "/admin/medicamentos",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosIndex} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/index",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosIndex} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/categorias",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosCategorias} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/categorias/nova",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosCategoriasIndex} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/categorias/editar/:id",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosCategoriasIndex} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/principios-ativos",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosPrincipiosAtivos} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/principios-ativos/novo",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosPrincipiosAtivos} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/principios-ativos/editar/:id",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosPrincipiosAtivos} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/relacionamentos",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosRelacionamentos} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/novo",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosForm} /></AuthGuard>
  },
  {
    path: "/admin/medicamentos/editar/:id",
    element: <AuthGuard><AdminComponent component={AdminMedicamentosForm} /></AuthGuard>
  },
  
  // Admin marketing routes
  {
    path: "/admin/marketing/blog",
    element: <AuthGuard><AdminComponent component={AdminMarketing.Blog} /></AuthGuard>
  },
  {
    path: "/admin/marketing/blog/novo",
    element: <AuthGuard><AdminComponent component={AdminMarketing.BlogPost} /></AuthGuard>
  },
  {
    path: "/admin/marketing/blog/editar/:id",
    element: <AuthGuard><AdminComponent component={AdminMarketing.BlogPost} /></AuthGuard>
  },
  {
    path: "/admin/marketing/blog/editor",
    element: <AuthGuard><AdminComponent component={AdminMarketing.BlogEditor} /></AuthGuard>
  },
  {
    path: "/admin/marketing/blog/gerador",
    element: <AuthGuard><AdminComponent component={AdminMarketing.BlogGenerator} /></AuthGuard>
  },
  {
    path: "/admin/marketing/google-ads",
    element: <AuthGuard><AdminComponent component={AdminMarketing.GoogleAds} /></AuthGuard>
  },
  {
    path: "/admin/marketing/facebook",
    element: <AuthGuard><AdminComponent component={AdminMarketing.Facebook} /></AuthGuard>
  },
  {
    path: "/admin/marketing/instagram",
    element: <AuthGuard><AdminComponent component={AdminMarketing.Instagram} /></AuthGuard>
  },
  {
    path: "/admin/marketing/linkedin",
    element: <AuthGuard><AdminComponent component={AdminMarketing.LinkedIn} /></AuthGuard>
  },
  {
    path: "/admin/marketing/email",
    element: <AuthGuard><AdminComponent component={AdminMarketing.Email} /></AuthGuard>
  },
  {
    path: "/admin/marketing/whatsapp",
    element: <AuthGuard><AdminComponent component={AdminMarketing.WhatsApp} /></AuthGuard>
  },
  {
    path: "/admin/marketing/youtube",
    element: <AuthGuard><AdminComponent component={AdminMarketing.YouTube} /></AuthGuard>
  },
  
  // Admin propostas routes
  {
    path: "/admin/propostas/lista",
    element: <AuthGuard><AdminComponent component={AdminPropostas.Lista} /></AuthGuard>
  },
  {
    path: "/admin/propostas/nova",
    element: <AuthGuard><AdminComponent component={AdminPropostas.Nova} /></AuthGuard>
  },
  {
    path: "/admin/propostas/editar/:id",
    element: <AuthGuard><AdminComponent component={AdminPropostas.Editar} /></AuthGuard>
  },
  {
    path: "/admin/propostas/visualizar/:id",
    element: <AuthGuard><AdminComponent component={AdminPropostas.Visualizar} /></AuthGuard>
  },
  {
    path: "/admin/propostas/revisao/:id",
    element: <AuthGuard><AdminComponent component={AdminPropostas.Revisao} /></AuthGuard>
  },
  {
    path: "/admin/propostas/ofertas",
    element: <AuthGuard><AdminComponent component={AdminPropostas.Ofertas} /></AuthGuard>
  },
  {
    path: "/admin/propostas/condicoes",
    element: <AuthGuard><AdminComponent component={AdminPropostas.Condicoes} /></AuthGuard>
  },
  {
    path: "/admin/propostas/revisao-pedidos",
    element: <AuthGuard><AdminComponent component={AdminPropostas.RevisaoPedidos} /></AuthGuard>
  },
  {
    path: "/admin/propostas/tipos-leads",
    element: <AuthGuard><AdminComponent component={AdminPropostas.TiposLeads} /></AuthGuard>
  },
  {
    path: "/admin/propostas/revalidacao",
    element: <AuthGuard><AdminComponent component={AdminPropostas.Revalidacao} /></AuthGuard>
  },
  
  // Admin clientes routes
  {
    path: "/admin/clientes",
    element: <AuthGuard><AdminComponent component={AdminClientes.Index} /></AuthGuard>
  },
  {
    path: "/admin/clientes/adicionar",
    element: <AuthGuard><AdminComponent component={AdminClientes.Adicionar} /></AuthGuard>
  },
  {
    path: "/admin/clientes/visualizar/:id",
    element: <AuthGuard><AdminComponent component={AdminClientes.Visualizar} /></AuthGuard>
  },
  {
    path: "/admin/clientes/editar/:id",
    element: <AuthGuard><AdminComponent component={AdminClientes.Editar} /></AuthGuard>
  },
  
  // Admin parceiros routes
  {
    path: "/admin/parceiros",
    element: <AuthGuard><AdminComponent component={AdminParceiros.Index} /></AuthGuard>
  },
  {
    path: "/admin/parceiros/adicionar",
    element: <AuthGuard><AdminComponent component={AdminParceiros.Adicionar} /></AuthGuard>
  },
  {
    path: "/admin/parceiros/visualizar/:id",
    element: <AuthGuard><AdminComponent component={AdminParceiros.Visualizar} /></AuthGuard>
  },
  {
    path: "/admin/parceiros/editar/:id",
    element: <AuthGuard><AdminComponent component={AdminParceiros.Editar} /></AuthGuard>
  },
  {
    path: "/admin/parceiros/xml",
    element: <AuthGuard><AdminComponent component={AdminParceiros.Xml} /></AuthGuard>
  },
  {
    path: "/admin/parceiros/xml/adicionar",
    element: <AuthGuard><AdminComponent component={AdminParceiros.XmlAdd} /></AuthGuard>
  },
  {
    path: "/admin/parceiros/xml/editar/:id",
    element: <AuthGuard><AdminComponent component={AdminParceiros.XmlEdit} /></AuthGuard>
  },
  {
    path: "/admin/parceiros/relatorios",
    element: <AuthGuard><AdminComponent component={AdminParceiros.Relatorios} /></AuthGuard>
  },
  {
    path: "/admin/parceiros/usuarios",
    element: <AuthGuard><AdminComponent component={AdminParceiros.Usuarios} /></AuthGuard>
  },
  
  // Admin relatórios routes
  {
    path: "/admin/relatorios/alertas",
    element: <AuthGuard><AdminComponent component={AdminRelatorios.Alertas} /></AuthGuard>
  },
  {
    path: "/admin/relatorios/conversoes",
    element: <AuthGuard><AdminComponent component={AdminRelatorios.Conversoes} /></AuthGuard>
  },
  {
    path: "/admin/relatorios/marketing",
    element: <AuthGuard><AdminComponent component={AdminRelatorios.Marketing} /></AuthGuard>
  },
  {
    path: "/admin/relatorios/fornecedores",
    element: <AuthGuard><AdminComponent component={AdminRelatorios.Parceiros} /></AuthGuard>
  },
  {
    path: "/admin/relatorios/precos",
    element: <AuthGuard><AdminComponent component={AdminRelatorios.Precos} /></AuthGuard>
  },
  {
    path: "/admin/relatorios/buscas",
    element: <AuthGuard><AdminComponent component={AdminRelatorios.Buscas} /></AuthGuard>
  },
  
  // Admin configurações - carregada diretamente (sem lazy loading)
  {
    path: "/admin/configuracoes",
    element: <AuthGuard><ErrorBoundary><AdminConfiguracoes /></ErrorBoundary></AuthGuard>
  },
  {
    path: "/admin/newsletter",
    element: <AuthGuard requiredPermission="can_manage_newsletter"><AdminComponent component={AdminNewsletter} /></AuthGuard>
  },
  
  // Old blog routes redirected to marketing/blog
  {
    path: "/admin/blog",
    element: <AuthGuard><AdminComponent component={AdminMarketing.Blog} /></AuthGuard>
  },
  {
    path: "/admin/blog/novo",
    element: <AuthGuard><AdminComponent component={AdminMarketing.BlogPost} /></AuthGuard>
  },
  {
    path: "/admin/blog/editar/:id",
    element: <AuthGuard><AdminComponent component={AdminMarketing.BlogPost} /></AuthGuard>
  },
  {
    path: "/admin/blog/editor",
    element: <AuthGuard><AdminComponent component={AdminMarketing.BlogEditor} /></AuthGuard>
  },
  
  // Catch-all route for 404
  {
    path: "*",
    element: <NotFound />
  }
]);

export default router;
