
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

interface JudicialFormFieldProps {
  label: string;
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  type?: string;
  required?: boolean;
  pattern?: string;
  inputMode?: "text" | "tel" | "email" | "url" | "search" | "none" | "numeric" | "decimal";
  autoComplete?: string;
  placeholder?: string;
  isTextarea?: boolean;
  rows?: number;
}

export const JudicialFormField = ({
  label,
  id,
  name,
  value,
  onChange,
  type = "text",
  required = false,
  pattern,
  inputMode,
  autoComplete,
  placeholder,
  isTextarea = false,
  rows = 4
}: JudicialFormFieldProps) => {
  return (
    <div className="group">
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 mb-1 font-roboto"
      >
        {label} {required && <span className="text-primary">*</span>}
      </label>
      
      {isTextarea ? (
        <Textarea
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          rows={rows}
          className="w-full border border-gray-300 rounded-lg px-3 py-2 text-sm md:text-base focus:outline-none focus:ring-2 focus:ring-primary/50 font-roboto transition-all group-hover:border-primary/50"
          placeholder={placeholder}
          required={required}
          aria-required={required}
        />
      ) : (
        <Input
          type={type}
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          className="w-full border border-gray-300 rounded-lg px-3 py-2 text-sm md:text-base focus:outline-none focus:ring-2 focus:ring-primary/50 font-roboto transition-all group-hover:border-primary/50"
          required={required}
          pattern={pattern}
          inputMode={inputMode}
          autoComplete={autoComplete}
          placeholder={placeholder}
          aria-required={required ? "true" : undefined}
        />
      )}
    </div>
  );
};
