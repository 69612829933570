
import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Plus, Minus } from "lucide-react";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem = ({ question, answer }: FAQItemProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex w-full justify-between items-center text-left font-medium text-gray-900"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{question}</span>
        {isOpen ? <Minus className="h-5 w-5 text-primary" /> : <Plus className="h-5 w-5 text-primary" />}
      </button>
      {isOpen && (
        <div className="mt-2 text-gray-600">
          <p>{answer}</p>
        </div>
      )}
    </div>
  );
};

export const FAQ = () => {
  const categories = [
    {
      id: "geral",
      label: "Geral",
      faqs: [
        {
          question: "O que é o Preço Medicamentos?",
          answer: "O Preço Medicamentos é uma plataforma especializada na busca de medicamentos raros e de alto custo, oferecendo os melhores preços e condições do mercado."
        },
        {
          question: "Como faço para encontrar um medicamento?",
          answer: "Utilize nossa ferramenta de busca na página inicial. Digite o nome do medicamento ou princípio ativo e veja os resultados disponíveis."
        },
        {
          question: "Como funciona a entrega dos medicamentos encontrados?",
          answer: "A entrega é realizada pelas farmácias e fornecedores que exibimos nos resultados de busca. Você escolhe onde comprar e finaliza a compra diretamente com eles."
        }
      ]
    },
    {
      id: "compras",
      label: "Compras",
      faqs: [
        {
          question: "Quais formas de pagamento os fornecedores aceitam?",
          answer: "As formas de pagamento variam conforme cada farmácia ou fornecedor exibido na busca. Ao acessar o site ou canal de venda do fornecedor, você poderá visualizar as opções disponíveis."
        },
        {
          question: "Como funciona o prazo de entrega dos fornecedores?",
          answer: "O prazo de entrega depende da farmácia ou fornecedor escolhido, bem como da região e disponibilidade do medicamento. Essa informação será apresentada diretamente na plataforma do fornecedor, durante a finalização da compra."
        },
        {
          question: "Como acompanho o pedido após a compra?",
          answer: "O acompanhamento do pedido é feito diretamente com o fornecedor escolhido. Após finalizar a compra no site da farmácia, você receberá um código de rastreio ou instruções diretamente por eles."
        }
      ]
    },
    {
      id: "medicamentos",
      label: "Medicamentos",
      faqs: [
        {
          question: "Vocês trabalham com medicamentos controlados?",
          answer: "Sim, trabalhamos com medicamentos controlados, mas é necessário o envio da receita médica antes da liberação do pedido, conforme exigido pela legislação."
        },
        {
          question: "Como solicitar um medicamento que não encontro no site?",
          answer: "Entre em contato conosco pelo chat ou telefone. Nossa equipe irá verificar a disponibilidade e condições especiais para o medicamento solicitado."
        },
        {
          question: "Os medicamentos são originais?",
          answer: "Todos os medicamentos exibidos na Preço Medicamentos são oferecidos por fornecedores previamente verificados. Antes de se cadastrarem em nossa plataforma, esses fornecedores passam por um processo de validação que garante que comercializam apenas medicamentos originais, com registro na ANVISA e em conformidade com as exigências legais."
        }
      ]
    }
  ];

  return (
    <section id="perguntas-frequentes" className="py-16 bg-white">
      <div className="container">
        <div className="text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">Perguntas Frequentes</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Encontre respostas para as dúvidas mais comuns sobre nossos serviços e produtos.
          </p>
        </div>

        <div className="max-w-3xl mx-auto">
          <Tabs defaultValue="geral" className="w-full">
            <TabsList className="grid grid-cols-3 mb-8">
              {categories.map((category) => (
                <TabsTrigger key={category.id} value={category.id}>
                  {category.label}
                </TabsTrigger>
              ))}
            </TabsList>
            
            {categories.map((category) => (
              <TabsContent key={category.id} value={category.id} className="space-y-1">
                {category.faqs.map((faq, index) => (
                  <FAQItem key={index} question={faq.question} answer={faq.answer} />
                ))}
              </TabsContent>
            ))}
          </Tabs>
        </div>
      </div>
    </section>
  );
};
