import { useState } from "react";
import { Phone, Mail, MapPin, Facebook, Instagram, Linkedin, LogIn } from "lucide-react";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useNavigate } from "react-router-dom";
import { optimizeImage } from "@/utils/imageOptimizer";

export const Footer = () => {
  const [email, setEmail] = useState("");
  const [interestArea, setInterestArea] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  
  const logoPath = "/lovable-uploads/00db7f97-33a2-4007-a490-06f3145c2c2a.png";
  const anvisaLogoPath = "/lovable-uploads/356e7276-f74b-435a-8f70-5866e2d0f320.png";
  
  const optimizedLogoUrl = optimizeImage(logoPath, {
    size: 'medium',
    format: 'webp',
    quality: 100
  });
  
  const optimizedAnvisaLogoUrl = optimizeImage(anvisaLogoPath, {
    size: 'small',
    format: 'webp',
    quality: 90
  });

  const handleSubscribe = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!email.trim()) {
      toast.error("Por favor, informe seu e-mail");
      return;
    }
    setIsSubmitting(true);
    try {
      const {
        error
      } = await supabase.from("newsletter_subscribers").insert([{
        email,
        interest_area: interestArea
      }]);
      if (error) {
        if (error.code === "23505") {
          toast.error("Este e-mail já está inscrito em nossa newsletter");
        } else {
          console.error("Error subscribing to newsletter:", error);
          toast.error("Ocorreu um erro ao se inscrever. Por favor, tente novamente");
        }
      } else {
        toast.success("Você foi inscrito com sucesso em nossa newsletter!");
        setEmail("");
        setInterestArea("");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Ocorreu um erro ao se inscrever. Por favor, tente novamente");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLoginClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate("/login");
    window.scrollTo(0, 0);
  };

  const interestOptions = [{
    value: "medicamentos-raros",
    label: "Medicamentos Raros"
  }, {
    value: "doencas-raras",
    label: "Doenças Raras"
  }, {
    value: "oncologia",
    label: "Oncologia"
  }, {
    value: "neurologia",
    label: "Neurologia"
  }, {
    value: "cardiologia",
    label: "Cardiologia"
  }, {
    value: "imunologia",
    label: "Imunologia"
  }, {
    value: "endocrinologia",
    label: "Endocrinologia"
  }, {
    value: "principios-ativos",
    label: "Princípios Ativos"
  }];

  const sectionHeadingClass = "text-lg font-semibold text-primary mb-4";

  return <footer className="bg-blue-50 text-gray-700">
      <div className="container py-8 md:py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div>
            <div className="flex items-center mb-4">
              <img 
                src={optimizedLogoUrl} 
                alt="Preço Medicamentos" 
                className="h-12 w-auto mr-2" 
                width="120"
                height="48" 
                loading="lazy"
                onError={(e) => {
                  const img = e.currentTarget as HTMLImageElement;
                  img.src = logoPath;
                }}
              />
            </div>
            <p className="text-gray-600 mb-4 text-sm md:text-base">
              Especialistas em medicamentos raros e de alto custo, oferecendo as melhores condições e atendimento personalizado.
            </p>
            <div className="flex space-x-4">
              <a href="https://facebook.com/precomedicamentos" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-primary transition-colors" aria-label="Facebook">
                <Facebook size={20} />
              </a>
              <a href="https://instagram.com/precomedicamentos" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-primary transition-colors" aria-label="Instagram">
                <Instagram size={20} />
              </a>
              <a href="https://linkedin.com/company/precomedicamentos" target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-primary transition-colors" aria-label="LinkedIn">
                <Linkedin size={20} />
              </a>
            </div>
          </div>
          
          <div>
            <h3 className={sectionHeadingClass}>Links Rápidos</h3>
            <ul className="space-y-2">
              <li>
                <a href="/" className="text-gray-600 hover:text-primary transition-colors text-sm md:text-base">Home</a>
              </li>
              <li>
                <a href="/quem-somos" className="text-gray-600 hover:text-primary transition-colors text-sm md:text-base">Quem Somos</a>
              </li>
              <li>
                <a href="/medicamentos" className="text-gray-600 hover:text-primary transition-colors text-sm md:text-base">Medicamentos</a>
              </li>
              <li>
                <a href="/como-funciona" className="text-gray-600 hover:text-primary transition-colors text-sm md:text-base">Como Funciona</a>
              </li>
              <li>
                <a href="/blog" className="text-gray-600 hover:text-primary transition-colors text-sm md:text-base">Blog</a>
              </li>
              <li>
                <a href="/login" className="text-gray-600 hover:text-primary transition-colors flex items-center text-sm md:text-base" onClick={handleLoginClick}>
                  <LogIn className="h-4 w-4 mr-1.5" />
                  <span>Entrar</span>
                </a>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className={sectionHeadingClass}>Contato</h3>
            <ul className="space-y-3">
              <li className="flex items-start text-sm md:text-base">
                <Phone className="h-5 w-5 mr-3 text-primary mt-0.5 flex-shrink-0" />
                <span>0800 557 7000</span>
              </li>
              <li className="flex items-start text-sm md:text-base">
                <Mail className="h-5 w-5 mr-3 text-primary mt-0.5 flex-shrink-0" />
                <span className="break-all">contato@precomedicamentos.com.br</span>
              </li>
              <li className="flex items-start text-sm md:text-base">
                <MapPin className="h-5 w-5 mr-3 text-primary mt-0.5 flex-shrink-0" />
                <span>Rua Clóvis Soares, 200 - 6º andar
Jardim Alvinopolis, Atibaia - SP
CEP: 12942-560</span>
              </li>
            </ul>
          </div>
          
          <div>
            <h3 className={sectionHeadingClass}>Newsletter</h3>
            <p className="text-gray-600 mb-4 text-sm md:text-base">
              Inscreva-se para receber atualizações sobre novos medicamentos e promoções.
            </p>
            <form className="space-y-2" onSubmit={handleSubscribe}>
              <Select value={interestArea} onValueChange={setInterestArea}>
                <SelectTrigger className="w-full px-4 py-2 rounded bg-white/90 border border-blue-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary text-sm">
                  <SelectValue placeholder="Selecione sua área de interesse" />
                </SelectTrigger>
                <SelectContent className="bg-white border border-blue-200 text-gray-700">
                  {interestOptions.map(option => <SelectItem key={option.value} value={option.value} className="text-gray-700 hover:bg-blue-50 text-sm">
                      {option.label}
                    </SelectItem>)}
                </SelectContent>
              </Select>
              
              <input type="email" placeholder="Seu e-mail" className="w-full px-4 py-2 rounded bg-white/90 border border-blue-200 text-gray-700 focus:outline-none focus:ring-2 focus:ring-primary text-sm" value={email} onChange={e => setEmail(e.target.value)} required />
              <button type="submit" className="w-full px-4 py-2 rounded bg-primary hover:bg-primary-dark text-white font-medium transition-colors disabled:opacity-70 disabled:cursor-not-allowed text-sm" disabled={isSubmitting}>
                {isSubmitting ? "Enviando..." : "Inscrever-se"}
              </button>
            </form>
          </div>
        </div>
        
        <div className="mt-8 md:mt-12 pt-6 border-t border-blue-200">
          <div className="flex flex-col md:flex-row items-center gap-4 bg-blue-100 rounded-lg p-3 md:p-4">
            <img 
              src={optimizedAnvisaLogoUrl} 
              alt="ANVISA" 
              className="h-16 md:h-20 object-contain" 
              width="80" 
              height="80" 
              loading="lazy"
              onError={(e) => {
                const img = e.currentTarget as HTMLImageElement;
                img.src = anvisaLogoPath;
                console.log("Failed to load optimized ANVISA logo, falling back to original");
              }}
            />
            <div className="text-[10px] md:text-xs text-gray-700 leading-tight">
              <p className="mb-1">
                Todas as informações contidas neste site têm a intenção de informar e educar, não pretendendo, de forma alguma, substituir as orientações de um profissional médico ou servir como recomendação para qualquer tipo de tratamento. Decisões relacionadas ao tratamento de pacientes devem ser tomadas por profissionais autorizados, considerando as características particulares de cada pessoa.
              </p>
              <p className="font-bold">
                SE PERSISTIREM OS SINTOMAS, PROCURE ORIENTAÇÃO DO FARMACÊUTICO OU DE SEU MÉDICO. LEIA A BULA.
              </p>
            </div>
          </div>
        </div>
        
        <div className="border-t border-blue-200 mt-6 pt-6 text-xs md:text-sm text-gray-600 flex flex-col md:flex-row justify-between items-center">
          <p>&copy; 2025 Preço Medicamentos. Todos os direitos reservados.</p>
          <div className="flex flex-wrap justify-center space-x-3 mt-2 md:mt-0">
            <a href="/politica-privacidade" className="hover:text-primary transition-colors whitespace-nowrap">Política de Privacidade</a>
            <a href="/politica-compliance" className="hover:text-primary transition-colors whitespace-nowrap">Política de Compliance</a>
          </div>
        </div>
      </div>
    </footer>;
};
