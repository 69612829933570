
import { supabase } from "@/integrations/supabase/client";

// Define simple, focused interface types
export interface UserProfile {
  id: string;
  role?: string | null;
  name?: string | null;
}

export type SimpleUserProfile = {
  role?: string | null;
};

export const fetchUserProfile = async (userId: string): Promise<UserProfile | null> => {
  try {
    console.log("Fetching profile for user ID:", userId);
    
    const { data: profileData, error } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
      .maybeSingle();
    
    if (error) {
      console.error("Error fetching user profile:", error);
      return null;
    }
    
    console.log("Profile data fetched:", profileData);
    
    if (!profileData) {
      console.warn("No profile found for user:", userId);
      
      // Get user data from auth to get the correct email
      const { data: userData, error: userError } = await supabase.auth.admin.getUserById(userId);
      
      if (userError) {
        console.error("Error getting user data:", userError);
      }
      
      const userEmail = userData?.user?.email || null;
      console.log("User email from auth:", userEmail);
      
      console.log("Will create profile for user with admin role");
      
      // If no profile exists, create one with admin role and the correct email from auth
      const { data: createdProfile, error: createError } = await supabase
        .from('profiles')
        .insert({
          id: userId,
          role: 'administrador_master',
          name: 'Administrador Master',
          email: userEmail // Use the email from the auth
        })
        .select()
        .maybeSingle();
      
      if (createError) {
        console.error("Error creating profile:", createError);
        return null;
      }
      
      console.log("Created profile:", createdProfile);
      
      return {
        id: userId,
        role: 'administrador_master',
        name: 'Administrador Master'
      };
    }
    
    // If the profile exists but email is missing or incorrect, update it
    if (profileData && (!profileData.email || profileData.email.includes('@example.com'))) {
      console.log("Profile has incorrect email, updating...");
      
      // Get user data from auth to get the correct email
      const { data: userData, error: userError } = await supabase.auth.getUser();
      
      if (userError) {
        console.error("Error getting user data:", userError);
      }
      
      if (userData?.user?.email) {
        console.log("Updating profile with correct email:", userData.user.email);
        
        const { error: updateError } = await supabase
          .from('profiles')
          .update({
            email: userData.user.email
          })
          .eq('id', userId);
        
        if (updateError) {
          console.error("Error updating profile email:", updateError);
        } else {
          console.log("Profile email updated successfully");
          // Update profileData with the correct email
          profileData.email = userData.user.email;
        }
      }
    }
    
    return {
      id: userId,
      role: profileData.role || null,
      name: profileData.name || null
    };
  } catch (error) {
    console.error("Error in fetchUserProfile:", error);
    return null;
  }
};

// Use the simple type for role checking
export const isAdminUser = (profile: SimpleUserProfile | null): boolean => {
  if (!profile) {
    console.log("Profile is null, not an admin");
    return false;
  }
  return isAdminRole(profile.role);
};

// Simple function to check if a string is an admin role
export const isAdminRole = (role?: string | null): boolean => {
  if (!role) {
    console.log("Role is null or undefined, not an admin");
    return false;
  }
  
  const hasAdminAccess = role === "administrador" || 
                       role === "administrador_master" || 
                       role === "juridico" || 
                       role === "sdr" || 
                       role === "parceiro_fornecedor" || 
                       role === "marketing";
                       
  console.log(`Checking if role '${role}' has admin access: ${hasAdminAccess}`);
  return hasAdminAccess;
};
