
import { JudicialFormField } from "../JudicialFormField";

interface FormFieldsProps {
  formData: {
    name: string;
    email: string;
    phone: string;
    medicine: string;
    details: string;
  };
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
}

export const FormFields = ({ formData, handleChange }: FormFieldsProps) => {
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
        <JudicialFormField
          label="Nome Completo"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          autoComplete="name"
          placeholder="Seu nome completo"
        />
        
        <JudicialFormField
          label="E-mail"
          id="email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
          autoComplete="email"
          placeholder="seu@email.com"
        />
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-4">
        <JudicialFormField
          label="Telefone"
          id="phone"
          name="phone"
          type="tel"
          value={formData.phone}
          onChange={handleChange}
          required
          pattern="[0-9\(\)\+\s\-]*"
          inputMode="tel"
          autoComplete="tel"
          placeholder="(00) 00000-0000"
        />
        
        <JudicialFormField
          label="Medicamento"
          id="medicine"
          name="medicine"
          value={formData.medicine}
          onChange={handleChange}
          required
          placeholder="Nome do medicamento"
        />
      </div>
      
      <JudicialFormField
        label="Detalhes do pedido"
        id="details"
        name="details"
        value={formData.details}
        onChange={handleChange}
        isTextarea
        rows={3}
        placeholder="Informe detalhes adicionais sobre seu pedido"
      />
    </>
  );
};
