
import React, { useEffect, useRef, useState } from "react";
import { ArrowRight, TrendingUp } from "lucide-react";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Card } from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "@/components/ui/carousel";
import type { CarouselApi } from "@/components/ui/carousel";
import { supabase } from "@/integrations/supabase/client";
import { useQuery } from "@tanstack/react-query";
import { optimizeImage } from "@/utils/imageOptimizer";

interface Medication {
  id: string;
  name: string;
  slug: string;
}

// Fetch real medications from database with optimized query
const fetchTrendingMedications = async (): Promise<Medication[]> => {
  const { data, error } = await supabase
    .from('medications')
    .select('id, name, slug') // Only select what we need
    .order('created_at', { ascending: false })
    .limit(7);
  
  if (error) {
    console.error("Error fetching medications:", error);
    throw error;
  }
  
  return data || [];
};

export function TrendingMedications() {
  const navigate = useNavigate();
  const [api, setApi] = React.useState<CarouselApi>();
  const intervalRef = useRef<number | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLElement>(null);

  const { data: medications, isLoading, error } = useQuery({
    queryKey: ['trending-medications'],
    queryFn: fetchTrendingMedications,
    staleTime: 5 * 60 * 1000, // Cache for 5 minutes
  });

  // Use new medication image
  const defaultMedicationImage = "/lovable-uploads/e9b3a210-5f86-4675-9163-e7230e071b87.png";

  // Lazy load carousel functionality when section becomes visible
  useEffect(() => {
    if (!api) return;
    
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          
          // Auto-scroll every 4 seconds only when visible
          intervalRef.current = window.setInterval(() => {
            api.scrollNext();
          }, 4000);
        } else {
          // Clear interval when not visible
          if (intervalRef.current !== null) {
            window.clearInterval(intervalRef.current);
            intervalRef.current = null;
          }
        }
      },
      { threshold: 0.1 }
    );
    
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    
    // Clean up
    return () => {
      if (intervalRef.current !== null) {
        window.clearInterval(intervalRef.current);
      }
      
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, [api]);

  const handleMedicineClick = (slug: string) => {
    navigate(`/medicamentos/${slug}`);
  };

  if (isLoading) {
    return (
      <section ref={sectionRef} className="py-10 sm:py-16 bg-white relative">
        <div className="container">
          <div className="text-center mb-8 sm:mb-12">
            <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-3">
              Carregando medicamentos...
            </h2>
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    console.error("Error loading medications:", error);
    return null; // Don't show section on error for better performance
  }

  return (
    <section ref={sectionRef} className="py-10 sm:py-16 bg-white relative overflow-hidden">
      <div className="container relative z-10">
        <div className="text-center mb-6 sm:mb-12">
          <div className="inline-flex items-center px-3 py-1 rounded-full bg-primary/10 text-primary mb-3 sm:mb-4">
            <TrendingUp className="h-3.5 w-3.5 mr-1.5" />
            <span className="font-medium text-sm">Mais populares</span>
          </div>
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-900 mb-2 sm:mb-3">
            Medicamentos Mais Buscados
          </h2>
          <p className="text-base sm:text-lg text-gray-600 max-w-2xl mx-auto">
            Compare os preços dos medicamentos de alto custo mais requisitados
          </p>
        </div>
        
        <Carousel 
          className="w-full max-w-7xl mx-auto"
          opts={{
            align: "center",
            loop: true,
          }}
          setApi={setApi}
        >
          <CarouselContent>
            {medications?.map(medication => (
              <CarouselItem key={medication.id} className="sm:basis-1/2 md:basis-1/3 lg:basis-1/4 pl-4">
                <div 
                  className="group relative cursor-pointer"
                  onClick={() => handleMedicineClick(medication.slug)}
                >
                  <Card className="overflow-hidden border border-transparent shadow-sm hover:shadow-md transition-all duration-300 bg-white p-4 sm:p-6 h-[260px] sm:h-[280px] flex flex-col items-center justify-center rounded-xl">
                    {/* Optimized gradient - reduced complexity */}
                    <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-primary/20 via-primary/30 to-primary/20"></div>
                    
                    <div className="h-36 sm:h-40 flex items-center justify-center mb-4 sm:mb-6 relative">
                      <img 
                        src={defaultMedicationImage}
                        alt={medication.name} 
                        className="h-full w-auto max-h-32 max-w-[140px] object-contain relative z-10"
                        width="140"
                        height="140"
                        loading="lazy"
                      />
                    </div>
                    
                    <h3 className="font-medium text-center text-primary text-sm sm:text-base tracking-tight transition-colors duration-300 max-w-full px-2 truncate w-full">
                      {medication.name}
                    </h3>
                  </Card>
                </div>
              </CarouselItem>
            ))}
          </CarouselContent>
        </Carousel>
        
        <div className="text-center mt-8 sm:mt-12">
          <Button asChild variant="outline" className="border-primary text-primary hover:bg-primary/10 shadow-sm hover:shadow transition-all duration-300">
            <a href="/medicamentos" className="flex items-center">
              Ver Todos os Medicamentos
              <ArrowRight className="ml-2 h-4 w-4 transition-transform group-hover:translate-x-1" />
            </a>
          </Button>
        </div>
      </div>
    </section>
  );
}
