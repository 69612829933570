
import { Button } from "./ui/button";
import { User, MessageCircle, Settings, LogOut, Moon, Sun } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Switch } from "@/components/ui/switch";
import { getThemePreference, toggleTheme } from "@/utils/themeUtils";
import { useToast } from "@/hooks/use-toast";

interface MobileMenuProps {
  currentUser: any;
  isLoading: boolean;
  onLoginClick: () => void;
  onLogoutClick: () => void;
}

export const MobileMenu = ({
  currentUser,
  isLoading,
  onLoginClick,
  onLogoutClick
}: MobileMenuProps) => {
  const [darkMode, setDarkMode] = useState<boolean>(getThemePreference() === 'dark');
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSettingsClick = () => {
    navigate("/admin/configuracoes", { replace: true });
  };

  const handleMyAccountClick = () => {
    navigate("/admin/dashboard", { replace: true });
  };

  const handleThemeToggle = () => {
    const newTheme = toggleTheme();
    setDarkMode(newTheme === 'dark');
    toast({
      title: newTheme === 'dark' ? "Modo escuro ativado" : "Modo claro ativado",
      description: "A aparência da interface foi alterada.",
    });
  };

  const handleAttendanceClick = () => {
    window.open("https://bit.ly/digisacpm", "_blank");
  };

  return (
    <div className="md:hidden absolute top-16 left-0 right-0 bg-white border-b border-gray-100 shadow-lg z-50 animate-fade-in">
      <div className="container py-4 space-y-4">
        <a href="/medicamentos" className="block py-2 text-gray-700 hover:text-primary transition-colors">Medicamentos</a>
        <a href="/blog" className="block py-2 text-gray-700 hover:text-primary transition-colors">Blog</a>
        
        <div className="grid grid-cols-1 gap-2 pt-2">
          {currentUser ? (
            <>
              <Popover>
                <PopoverTrigger asChild>
                  <Button variant="outline" size="sm" className="w-full">
                    <User className="h-5 w-5 mr-1" />
                    <span>{currentUser.name || "Minha Conta"}</span>
                  </Button>
                </PopoverTrigger>
                <PopoverContent align="start" side="bottom" sideOffset={5} className="w-64 p-4">
                  <div className="space-y-3">
                    <h3 className="font-semibold text-lg border-b pb-2">{currentUser.name || "Usuário"}</h3>
                    
                    <Button 
                      variant="ghost" 
                      className="w-full justify-start" 
                      onClick={handleMyAccountClick}
                    >
                      <User className="mr-2 h-4 w-4" />
                      Minha Conta
                    </Button>
                    
                    <Button 
                      variant="ghost" 
                      className="w-full justify-start" 
                      onClick={handleSettingsClick}
                    >
                      <Settings className="mr-2 h-4 w-4" />
                      Configurações
                    </Button>
                    
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        {darkMode ? <Moon className="h-4 w-4 mr-2" /> : <Sun className="h-4 w-4 mr-2" />}
                        <span>Modo Escuro</span>
                      </div>
                      <Switch checked={darkMode} onCheckedChange={handleThemeToggle} />
                    </div>
                    
                    <Button 
                      variant="ghost" 
                      className="w-full justify-start text-red-500 hover:text-red-600 hover:bg-red-50"
                      onClick={onLogoutClick}
                    >
                      <LogOut className="mr-2 h-4 w-4" />
                      Sair
                    </Button>
                  </div>
                </PopoverContent>
              </Popover>
              <Button variant="ghost" size="sm" className="w-full" onClick={onLogoutClick} disabled={isLoading}>
                <span>{isLoading ? "Saindo..." : "Sair"}</span>
              </Button>
            </>
          ) : (
            // Removed the "Entrar" button here
            null
          )}
          <Button className="w-full" onClick={handleAttendanceClick}>
            <MessageCircle className="h-5 w-5 mr-1" />
            <span>Atendimento</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
