import { useState, useEffect, ReactNode, useRef } from "react";
import { getCurrentUser } from "./authUtils";
import { Navigate, useNavigate } from "react-router-dom";
import { initializeTheme } from "./themeUtils";
import { supabase } from "@/integrations/supabase/client";
import { Skeleton } from "@/components/ui/skeleton";
import { Loader2 } from "lucide-react";

interface AuthGuardProps {
  children: ReactNode;
  requireAdmin?: boolean;
  requiredPermission?: string;
}

export function AuthGuard({ 
  children, 
  requireAdmin = true,
  requiredPermission
}: AuthGuardProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasPermission, setHasPermission] = useState(true); // Default to true if no specific permission required
  const navigate = useNavigate();
  const hasCheckedAuth = useRef(false);

  // Define admin roles explicitly - ensure marketing is included
  const adminRoles = ["administrador", "administrador_master", "juridico", "sdr", "parceiro_fornecedor", "marketing"];

  useEffect(() => {
    // Initialize theme when component mounts
    initializeTheme();
    
    let isMounted = true;
    let authSubscription: { data: { subscription: { unsubscribe: () => void } } } | null = null;
    
    const checkAuthStatus = async () => {
      if (!isMounted || hasCheckedAuth.current) return;
      hasCheckedAuth.current = true;
      
      try {
        // Fast path - check localStorage/sessionStorage first
        const cachedAuth = sessionStorage.getItem('isAuthenticated');
        const cachedRole = localStorage.getItem('userRole');
        
        // Ultra-fast path for admin_master
        if (cachedAuth === 'true' && cachedRole === 'administrador_master') {
          console.log("AuthGuard: Cached admin_master detected");
          setIsAuthenticated(true);
          setIsAdmin(true);
          setHasPermission(true);
          setIsLoading(false);
          
          // Still set up listener for logout events
          setupAuthListener();
          return;
        }
        
        // Fast path for known authenticated users
        if (cachedAuth === 'true' && cachedRole && adminRoles.includes(cachedRole)) {
          console.log(`AuthGuard: Cached admin role detected: ${cachedRole}`);
          setIsAuthenticated(true);
          setIsAdmin(true);
          
          // Special case for Marketing role - check appropriate permissions
          if (cachedRole === 'marketing') {
            const marketingPermissions = [
              'can_view_dashboard',
              'can_manage_social_media',
              'can_manage_newsletter',
              'can_view_reports'
            ];
            
            // If the required permission is for something Marketing can access, grant it
            if (!requiredPermission || marketingPermissions.includes(requiredPermission)) {
              console.log(`AuthGuard: Marketing user granted access to ${requiredPermission || 'page'}`);
              setHasPermission(true);
              setIsLoading(false);
              setupAuthListener();
              return;
            }
          }
          
          // Para juridico e permissões de proposta - path rápido
          if (cachedRole === 'juridico' && 
              requiredPermission && 
              ['can_view_proposals', 'can_edit_proposals', 
               'can_approve_proposals', 'can_view_proposal_reviews'].includes(requiredPermission)) {
            setHasPermission(true);
            setIsLoading(false);
            setupAuthListener();
            return;
          }
          
          // Se precisamos verificar permissão específica, precisamos continuar
          if (!requiredPermission) {
            setIsLoading(false);
            setupAuthListener();
            return;
          }
        }
        
        console.log("AuthGuard: Verificando status de autenticação");
        
        // Set up auth state listener first
        setupAuthListener();
        
        // Then get current session and user data
        const user = await getCurrentUser();
        
        if (user && isMounted) {
          console.log("AuthGuard: Usuário autenticado:", user);
          setIsAuthenticated(true);
          
          // Immediate pass for admin_master - no further checks required
          if (user.role === 'administrador_master') {
            console.log("AuthGuard: Usuário é administrador master, acesso garantido");
            setIsAdmin(true);
            setHasPermission(true);
            setIsLoading(false);
            return;
          }
          
          // Check if user has an admin role
          const hasAdminRole = adminRoles.includes(user.role || '');
          setIsAdmin(hasAdminRole);
          
          console.log("AuthGuard: Usuário tem acesso ao admin?", hasAdminRole);
          
          // Check specific permission if required
          if (requiredPermission && isMounted) {
            // Special case for juridico - they always have access to proposal permissions
            if (user.role === 'juridico' && 
                (requiredPermission === 'can_view_proposals' || 
                 requiredPermission === 'can_edit_proposals' || 
                 requiredPermission === 'can_approve_proposals' ||
                 requiredPermission === 'can_view_proposal_reviews')) {
              console.log(`AuthGuard: Juridico user has permission ${requiredPermission}`);
              setHasPermission(true);
              setIsLoading(false);
              return;
            }
            
            // Check permission in the role_permissions table - only if needed
            const { data: roleData } = await supabase
              .from('role_permissions')
              .select(requiredPermission)
              .eq('role_name', user.role)
              .single();
            
            // Check if the specific permission is true
            const permissionResult = roleData && Boolean(roleData[requiredPermission]);
            
            setHasPermission(Boolean(permissionResult));
            console.log(`AuthGuard: User has permission '${requiredPermission}'?`, permissionResult);
            
            if (!permissionResult && isMounted) {
              console.log(`AuthGuard: Usuário não tem permissão '${requiredPermission}', redirecionando para dashboard`);
              navigate("/admin/dashboard", { replace: true });
            }
          }
          
          if (requireAdmin && !hasAdminRole && isMounted) {
            console.log("AuthGuard: Usuário não tem acesso admin, redirecionando para home");
            navigate("/", { replace: true });
          }
        } else if (isMounted) {
          console.log("AuthGuard: Nenhum usuário autenticado, redirecionando para login");
          setIsAuthenticated(false);
          setIsAdmin(false);
          sessionStorage.removeItem('isAuthenticated');
          localStorage.removeItem('userRole');
          
          if (requireAdmin) {
            navigate("/login", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        }
      } catch (error) {
        console.error("AuthGuard: Erro ao verificar autenticação:", error);
        if (isMounted) {
          setIsAuthenticated(false);
          setIsAdmin(false);
          sessionStorage.removeItem('isAuthenticated');
          localStorage.removeItem('userRole');
          
          if (requireAdmin) {
            navigate("/login", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };
    
    function setupAuthListener() {
      authSubscription = supabase.auth.onAuthStateChange(async (event, session) => {
        console.log("Auth state changed:", event);
        
        if (!isMounted) return;
        
        if (event === 'SIGNED_OUT' || !session) {
          if (requireAdmin) {
            setIsAuthenticated(false);
            setIsAdmin(false);
            setIsLoading(false);
            sessionStorage.removeItem('isAuthenticated');
            localStorage.removeItem('userRole');
            navigate("/login", { replace: true });
          }
        }
      });
    }
    
    // Execute verification immediately
    checkAuthStatus();
    
    return () => {
      isMounted = false;
      hasCheckedAuth.current = false;
      if (authSubscription) {
        authSubscription.data.subscription.unsubscribe();
      }
    };
  }, [navigate, requireAdmin, requiredPermission]);

  // Reduzido o tempo de exibição do loading para melhorar UX
  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen dark:bg-gray-900">
        <Loader2 className="h-12 w-12 animate-spin text-primary" />
        <p className="mt-4 text-sm text-muted-foreground">Verificando acesso...</p>
      </div>
    );
  }

  if (!isAuthenticated && requireAdmin) {
    return <Navigate to="/login" replace />;
  }

  if (requireAdmin && !isAdmin) {
    return <Navigate to="/" replace />;
  }
  
  if (requiredPermission && !hasPermission) {
    return <Navigate to="/admin/dashboard" replace />;
  }

  return <>{children}</>;
}
