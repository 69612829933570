
import { useEffect, useState, useRef, lazy, Suspense, ComponentType, startTransition } from 'react';
import { Loading } from '@/components/ui/loading';
import { ErrorBoundary } from '@/components/admin/common/ErrorBoundary';

/**
 * Hook otimizado para detectar quando um elemento entra na viewport
 * usando Intersection Observer API com opções de threshold opcionais
 */
export const useIntersectionObserver = (
  options = {
    threshold: 0,
    rootMargin: '200px',
  }
) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!ref.current) return;
    
    // Limpar observer anterior se existir
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    // Criar novo observer
    observerRef.current = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        // Use startTransition when setting state from intersection observer
        startTransition(() => {
          setIsVisible(true);
        });
        // Desconectar após detecção para economizar recursos
        observerRef.current?.disconnect();
      }
    }, options);

    // Observar elemento
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current?.disconnect();
    };
  }, [options.threshold, options.rootMargin]);

  return { ref, isVisible };
};

/**
 * Prefetch otimizado de componentes com priorização baseada em necessidade
 * com mecanismo de retry aprimorado
 */
export const prefetchResource = (
  importFn: () => Promise<any>,
  priority: 'high' | 'medium' | 'low' = 'medium',
  maxRetries = 2
) => {
  return new Promise((resolve, reject) => {
    // Determinar delay baseado na prioridade
    const delays = {
      high: 0,
      medium: 200,
      low: 1000
    };
    
    const attemptLoad = (retriesLeft: number) => {
      importFn()
        .then(resolve)
        .catch(error => {
          console.error(`Failed to load resource, retries left: ${retriesLeft}`, error);
          if (retriesLeft > 0) {
            // Exponential backoff
            const backoffDelay = (maxRetries - retriesLeft + 1) * 500;
            setTimeout(() => attemptLoad(retriesLeft - 1), backoffDelay);
          } else {
            reject(error);
          }
        });
    };
    
    // Usar requestIdleCallback para não impactar o thread principal
    if ('requestIdleCallback' in window) {
      window.requestIdleCallback(
        () => {
          attemptLoad(maxRetries);
        },
        { timeout: priority === 'high' ? 1000 : 2000 }
      );
    } else {
      // Fallback para setTimeout com delay baseado em prioridade
      setTimeout(() => {
        attemptLoad(maxRetries);
      }, delays[priority]);
    }
  });
};

/**
 * Carrega scripts externos com controle de prioridade e timeout
 */
export const loadExternalScript = (
  url: string,
  id: string,
  options: {
    async?: boolean,
    defer?: boolean,
    priority?: 'high' | 'low',
    timeout?: number,
    retries?: number
  } = {}
): Promise<void> => {
  const { 
    async = true, 
    defer = true,
    priority = 'low',
    timeout = 5000,
    retries = 2
  } = options;
  
  return new Promise((resolve, reject) => {
    // Verificar se o script já existe
    if (document.getElementById(id)) {
      resolve();
      return;
    }
    
    const loadScript = (retriesLeft: number) => {
      const script = document.createElement('script');
      script.id = id;
      script.src = url;
      script.async = async;
      script.defer = defer;
      
      // Adicionar atributo de prioridade se suportado
      if ('elementTiming' in HTMLScriptElement.prototype) {
        script.setAttribute('fetchpriority', priority);
      }
      
      // Configurar timeout para rejeitar se o script demorar muito
      const timeoutId = setTimeout(() => {
        if (retriesLeft > 0) {
          script.remove();
          console.warn(`Script load timeout: ${url}, retrying... (${retriesLeft} attempts left)`);
          loadScript(retriesLeft - 1);
        } else {
          reject(new Error(`Script load timeout after ${retries} attempts: ${url}`));
        }
      }, timeout);
      
      script.onload = () => {
        clearTimeout(timeoutId);
        resolve();
      };
      
      script.onerror = (error) => {
        clearTimeout(timeoutId);
        script.remove();
        
        if (retriesLeft > 0) {
          console.warn(`Script load error: ${url}, retrying... (${retriesLeft} attempts left)`);
          // Exponential backoff
          const backoffDelay = (retries - retriesLeft + 1) * 500;
          setTimeout(() => loadScript(retriesLeft - 1), backoffDelay);
        } else {
          reject(error);
        }
      };
      
      document.body.appendChild(script);
    };
    
    loadScript(retries);
  });
};

/**
 * Preconectar a origens importantes com prioridade
 */
export const preconnectToOrigins = (origins: Array<{url: string, priority?: 'high' | 'low'}>) => {
  origins.forEach(({url, priority = 'low'}) => {
    const link = document.createElement('link');
    link.rel = 'preconnect';
    link.href = url;
    link.crossOrigin = 'anonymous';
    
    // Adicionar fetchpriority se suportado
    if ('fetchPriority' in HTMLLinkElement.prototype) {
      link.setAttribute('fetchpriority', priority);
    }
    
    document.head.appendChild(link);
  });
};

/**
 * Componente otimizado para carregar componentes React sob demanda com Suspense
 * com melhor tratamento de erros e cache e sistema de retry aprimorado
 */
export function LazyLoad<T extends ComponentType<any>>(
  importFn: () => Promise<{ default: T }>,
  loadingProps?: {
    size?: 'xs' | 'sm' | 'md' | 'lg',
    text?: string,
    className?: string,
    showLoading?: boolean
  }
) {
  const { showLoading = false, ...props } = loadingProps || {};
  
  // Estratégia melhorada para carregamento de componentes com múltiplas tentativas
  const loadComponent = () => {
    // Criamos um cache para armazenar o módulo carregado
    let cachedModule: { default: T } | null = null;
    
    return new Promise<{ default: T }>((resolve, reject) => {
      // Se já temos o módulo em cache, retornamos imediatamente
      if (cachedModule) {
        resolve(cachedModule);
        return;
      }
      
      // Função para tentar carregar o módulo com retries
      const attemptLoad = (retriesLeft: number) => {
        importFn()
          .then((module) => {
            // Armazenamos em cache para futuras chamadas
            cachedModule = module;
            // Use startTransition para transições mais suaves
            startTransition(() => {
              // Pequeno timeout para garantir que o browser tenha tempo de processar
              setTimeout(() => resolve(module), 10);
            });
          })
          .catch((err) => {
            console.error(`Erro ao carregar componente dinâmico (tentativas restantes: ${retriesLeft}):`, err);
            
            // Se ainda temos tentativas, esperamos um pouco e tentamos novamente
            if (retriesLeft > 0) {
              // Backoff exponencial
              const delay = Math.min(1000 * (2 ** (3 - retriesLeft)), 5000);
              setTimeout(() => attemptLoad(retriesLeft - 1), delay);
            } else {
              // Sem mais tentativas, rejeitamos a promessa
              console.error("Todas as tentativas de carregamento do componente falharam");
              reject(err);
            }
          });
      };
      
      // Iniciamos com 3 tentativas
      attemptLoad(3);
    });
  };
  
  const LazyComponent = lazy(loadComponent);
  
  const ErrorFallback = () => (
    <div className="p-4 bg-red-50 border border-red-200 rounded-md text-red-800">
      <h3 className="text-lg font-medium">Erro ao carregar componente</h3>
      <p className="mt-1">Ocorreu um erro ao carregar esta seção. Por favor, tente recarregar a página.</p>
      <button 
        onClick={() => window.location.reload()}
        className="mt-3 px-4 py-2 bg-red-100 hover:bg-red-200 rounded-md text-red-700 text-sm font-medium transition-colors"
      >
        Recarregar página
      </button>
    </div>
  );
  
  return (props: React.ComponentProps<T>) => (
    <Suspense fallback={showLoading ? <Loading suspenseFallback={true} {...props} /> : null}>
      <ErrorBoundary fallback={<ErrorFallback />}>
        <LazyComponent {...props} />
      </ErrorBoundary>
    </Suspense>
  );
}
