import { Search, MessageCircle, Pill, ArrowRight, Percent } from "lucide-react";
import { Button } from "@/components/ui/button";
import { GlassCard } from "@/components/GlassCard";
export function HowItWorksSection() {
  return <section className="py-24 bg-gray-50 dark:bg-gray-800/50 relative">
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_30%_20%,rgba(0,141,213,0.1),transparent)]" />
      
      <div className="container relative z-10">
        <div className="text-center mb-16">
          <span className="text-primary font-semibold uppercase tracking-wider px-4 py-1.5 bg-primary/10 rounded-full inline-block mb-4">Processo Simplificado</span>
          <h2 className="text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-6">Como Funciona</h2>
          <p className="text-xl text-gray-600 dark:text-gray-300 max-w-3xl mx-auto">
            Facilitamos o acesso a medicamentos raros e de alto custo com um processo simples e eficiente.
          </p>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <GlassCard className="p-10 text-center transform transition-all hover:scale-105 hover:shadow-lg duration-300">
            <div className="w-20 h-20 rounded-2xl bg-gradient-to-br from-primary to-primary-dark flex items-center justify-center mx-auto mb-8">
              <Search className="h-10 w-10 text-white" />
              <div className="absolute -right-2 -top-2 w-8 h-8 rounded-full bg-white dark:bg-gray-800 flex items-center justify-center border-2 border-primary font-bold text-primary">1</div>
            </div>
            <h3 className="text-2xl font-semibold mb-4">Busque o Medicamento</h3>
            <p className="text-gray-600 dark:text-gray-300">
              Utilize nossa ferramenta de busca avançada para encontrar o medicamento que você precisa.
            </p>
          </GlassCard>
          
          <GlassCard className="p-10 text-center transform transition-all hover:scale-105 hover:shadow-lg duration-300">
            <div className="w-20 h-20 rounded-2xl bg-gradient-to-br from-primary to-primary-dark flex items-center justify-center mx-auto mb-8">
              <MessageCircle className="h-10 w-10 text-white" />
              <div className="absolute -right-2 -top-2 w-8 h-8 rounded-full bg-white dark:bg-gray-800 flex items-center justify-center border-2 border-primary font-bold text-primary">2</div>
            </div>
            <h3 className="text-2xl font-semibold mb-4">Solicite um Orçamento</h3>
            <p className="text-gray-600 dark:text-gray-300">
              Envie sua solicitação ou fale diretamente com um de nossos especialistas para obter um orçamento.
            </p>
          </GlassCard>
          
          <GlassCard className="p-10 text-center transform transition-all hover:scale-105 hover:shadow-lg duration-300">
            <div className="w-20 h-20 rounded-2xl bg-gradient-to-br from-primary to-primary-dark flex items-center justify-center mx-auto mb-8">
              <Pill className="h-10 w-10 text-white" />
              <div className="absolute -right-2 -top-2 w-8 h-8 rounded-full bg-white dark:bg-gray-800 flex items-center justify-center border-2 border-primary font-bold text-primary">3</div>
            </div>
            <h3 className="text-2xl font-semibold mb-4">Receba sua Cotação</h3>
            <p className="text-gray-600 dark:text-gray-300">Receba sua cotação em até 24 horas e descubra onde comprar com mais economia e segurança.</p>
            <div className="mt-4 flex items-center justify-center text-primary">
              <Percent className="h-5 w-5 mr-1" />
              <span className="font-medium">Melhor preço garantido</span>
            </div>
          </GlassCard>
        </div>
        
        <div className="text-center mt-16">
          <Button asChild size="lg" className="bg-gradient-to-r from-primary to-primary-dark hover:opacity-90 transition-all px-8 font-bold">
            <a href="/como-funciona">
              Saiba Mais
              <ArrowRight className="ml-2 h-5 w-5" />
            </a>
          </Button>
        </div>
      </div>
    </section>;
}