
import { LucideIcon } from "lucide-react";

interface ModernFeatureCardProps {
  icon: LucideIcon;
  title: string;
  description: string;
}

export function ModernFeatureCard({ icon: Icon, title, description }: ModernFeatureCardProps) {
  return (
    <div className="flex flex-col items-center p-6 rounded-2xl transition-all duration-300 
                   hover:bg-white/80 dark:hover:bg-gray-800/60 
                   border border-transparent hover:border-primary/20 group">
      <div className="h-14 w-14 rounded-full bg-gradient-to-br from-primary to-primary-dark 
                      flex items-center justify-center mb-5 group-hover:scale-110 transition-transform">
        <Icon className="h-7 w-7 text-white" />
      </div>
      <h3 className="font-semibold text-xl mb-3 text-gray-900 dark:text-white group-hover:text-primary transition-colors text-center">{title}</h3>
      <p className="text-muted-foreground text-center">{description}</p>
    </div>
  );
}
